import DeviceTaskIcon from '@material-ui/icons/PlaylistAddCheck';
import DeviceTaskList from './DeviceTaskList';
import DeviceTaskShow from './DeviceTaskShow';
import DeviceTaskEdit from './DeviceTaskEdit';
import DeviceTaskCreate from './DeviceTaskCreate';

export default {
	list: DeviceTaskList,
	show: DeviceTaskShow,
	edit: DeviceTaskEdit,
	create: DeviceTaskCreate,
	icon: DeviceTaskIcon,
	name: 'deviceTask'
};
