import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab } from 'react-admin';

import { DateField } from '../customFields';
import ExchangeRateTitle from './ExchangeRateTitle';

const ExchangeRateShow = (props) => (
    <Show title={<ExchangeRateTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Details"> 
                <DateField source="currencyDate" />
                <TextField source="currencyCode" />
                <NumberField source="currencyRate" />
                <TextField source="sourceId" />
                <BooleanField source="active" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ExchangeRateShow;
