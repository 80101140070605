import React from 'react';
import { Datagrid, TextField, BooleanField, EditButton } from 'react-admin';

import { DefaultFields, CompanyReferenceField } from '../customFields';

const ShopDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		{/* <TextField source="logo" /> */}
		<BooleanField source="customersActive" />
		<BooleanField source="customersMandatory" />
		<BooleanField source="stampArea" />
		{/* <TextField source="paymentMethods" /> */}
		<TextField source="city" />
		{/* <ReferenceField source="countryId" reference="countries">
			<TextField source="id" />
		</ReferenceField> */}
		<TextField source="postcode" />
		<TextField source="address" />
		{CompanyReferenceField(props)}
		{DefaultFields(props)}
		<EditButton />
	</Datagrid>
);

export default ShopDataGrid;
