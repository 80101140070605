import DiscountIcon from '@material-ui/icons/MoneyOff';
import DiscountList from './DiscountList';
import DiscountShow from './DiscountShow';
import DiscountEdit from './DiscountEdit';
import DiscountCreate from './DiscountCreate';

export default {
	list: DiscountList,
	show: DiscountShow,
	edit: DiscountEdit,
	create: DiscountCreate,
	icon: DiscountIcon,
	name: 'discount'
};
