import React from 'react';
import { Edit, FormTab, FormDataConsumer } from 'react-admin';

import { TabbedForm } from '../customViews';
import { ReferenceAutocompleteInput, NameAutocompleteInput } from '../customInputs';
import RoleMappingTitle from './RoleMappingTitle';

const RoleMappingEdit = (props) => {
	return (
		<Edit title={<RoleMappingTitle />} {...props}>
			<TabbedForm>
				<FormTab label="Details">
					<ReferenceAutocompleteInput source="principalId" reference="account" searchField="fullName" />
					<NameAutocompleteInput source="roleId" reference="roles" />
					<FormDataConsumer>
						{({ formData, ...rest }) => {
							if ([1, 2, 3, 4, 5].includes(formData.roleId)) {
								formData.shopId = null;
								if (formData.roleId === 1) {
									formData.companyId = null;
								}
							}
							if ([1, 6, 7, 8, 9].includes(formData.roleId)) {
								formData.companyId = null;
								if (formData.roleId === 1) {
									formData.shopId = null;
								}
							}

							return (
								<>
									{[2, 3, 4, 5].includes(formData.roleId) && (
										<NameAutocompleteInput
											source="companyId"
											reference="company"
											{...rest}
										/>
									)}

									{[6, 7, 8, 9].includes(formData.roleId) && (
										<NameAutocompleteInput
											source="shopId"
											reference="shop"
											{...rest}
										/>
									)}
								</>
							);
						}}
					</FormDataConsumer>
				</FormTab>
			</TabbedForm>
		</Edit>
	)
};

export default RoleMappingEdit;
