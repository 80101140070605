import { ReferenceField as ReactRefererenceField } from 'react-admin';

const RefererenceField = ReactRefererenceField;

RefererenceField.defaultProps = {
	allowEmpty: true,
	addLabel: true
};

export default RefererenceField;
