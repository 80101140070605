import React from 'react';
import Typography from '@material-ui/core/Typography';

const Aside = (props) => {
	const { title, message, width } = props;
	return (
		<div style={{ width: width || 200, margin: '1em' }}>
			<Typography variant="title">{title}</Typography>
			<Typography variant="body1">{message}</Typography>
		</div>
	);
};

export default Aside;
