import React from 'react';
import { Edit, TextInput, BooleanInput, NumberInput, DisabledInput, FormTab } from 'react-admin';

import { TabbedForm } from '../customViews';
import ProductTitle from './ProductTitle';

const ProductEdit = (props) => (
	<Edit title={<ProductTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<DisabledInput source="id" />
				<TextInput source="name" />
				<TextInput source="displayName" />
				<TextInput source="info" />
				<NumberInput source="price" />
				<TextInput source="abbreviation" />
				<NumberInput source="criticalStock" />
				{/* <ReferenceField source="supplierId" reference="suppliers">
			<TextField source="id" />
		</ReferenceField> */}
				{/* <ReferenceField source="colorId" reference="colors">
			<TextField source="id" />
		</ReferenceField> */}
				{/* <ReferenceField source="categoryId" reference="categories">
			<TextField source="id" />
		</ReferenceField> */}

				<NumberInput source="vat" />
				{/* <ReferenceField source="unitTypeId" reference="unitTypes">
			<TextField source="id" />
		</ReferenceField> */}
				<TextInput source="barcode" />
				<TextInput source="erpcode" />
				<TextInput source="erpType" />
				<NumberInput source="type" />
				<BooleanInput source="manageStock" />
				<BooleanInput source="kitchenProduct" />
				<BooleanInput source="barProduct" />
				<BooleanInput source="singular" />
				{/* <TextInput source="images" /> */}
				<BooleanInput source="isMandatory" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default ProductEdit;
