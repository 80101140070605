import React from 'react';
import { Show, TextField, BooleanField, TabbedShowLayout, Tab } from 'react-admin';
import { withStyles } from '@material-ui/core';

import { JsonField, ShopReferenceField, DateField } from '../customFields';

import RegisterTitle from './RegisterTitle';

const styles = {
	inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
};

const RegisterShow = withStyles(styles)(({ classes, ...props }) => (
	<Show title={<RegisterTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				{ShopReferenceField(props)}
				<TextField source="name" />
				<TextField source="serialNumber" formClassName={classes.inlineBlock} />
				<TextField source="osName" formClassName={classes.inlineBlock} />
				<TextField source="osVersion" />
				<TextField source="appVersion" />
				<TextField source="ipAddress" />
				<TextField source="macAddress" />
				<BooleanField source="active" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
				<TextField source="createdBy" />
				<TextField source="updatedBy" />
			</Tab>
			<Tab label="Settings" path="settings">
				<JsonField source="properties" />
			</Tab>
		</TabbedShowLayout>
	</Show>
));

export default RegisterShow;
