import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab } from 'react-admin';

import { DateField } from '../customFields';
import AmounTypeTitle from './AmountTypeTitle';

const AmounTypeShow = (props) => (
	<Show title={<AmounTypeTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<TextField source="currencyCode" />
				<NumberField source="index" />
				<BooleanField source="isDefault" />
				<BooleanField source="isPaymentType" />
				<BooleanField source="isCrossChecked" />
				<BooleanField source="isCreditCard" />
				<BooleanField source="isTicket" />
				<BooleanField source="isCash" />
				<BooleanField source="isOpeningAmount" />
				<TextField source="ingenicoAppId" reference="ingenicoApps" />
				<BooleanField source="active" />
				<DateField source="createdAt"  />
				<DateField source="updatedAt"  />
				<TextField source="createdBy" />
				<TextField source="updatedBy" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default AmounTypeShow;
