import React from 'react';
import { Create, FormDataConsumer, FormTab } from 'react-admin';

import { TabbedForm } from '../customViews';
import { ReferenceAutocompleteInput, NameAutocompleteInput } from '../customInputs';

import RoleMappingTitle from './RoleMappingTitle';

const redirect = (basePath, id, data) => `/account/${data.principalId}/show/roles`;

const RoleMappingCreate = (props) => (
	<Create title={<RoleMappingTitle />} {...props}>
		<TabbedForm redirect={redirect}>
			<FormTab label="Details">
				<ReferenceAutocompleteInput source="principalId" reference="account" searchField="fullName" />
				<NameAutocompleteInput source="roleId" reference="roles" />
				<FormDataConsumer>
					{({ formData, ...rest }) =>
						[ 2, 3, 4, 5 ].includes(formData.roleId) && (
							<NameAutocompleteInput source="companyId" reference="company" />
						)}
				</FormDataConsumer>

				<FormDataConsumer>
					{({ formData, ...rest }) =>
						[ 6, 7, 8, 9 ].includes(formData.roleId) && (
							<NameAutocompleteInput source="shopId" reference="shop" />
						)}
				</FormDataConsumer>
			</FormTab>
		</TabbedForm>
	</Create>
);

export default RoleMappingCreate;
