import React from 'react';
import { Datagrid, TextField, NumberField, EditButton } from 'react-admin';

import { CompanyReferenceField, DefaultFields } from '../customFields';

const DiscountDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		<NumberField source="type" />
		<NumberField source="amount" />
		<TextField source="code" />
		{CompanyReferenceField(props)}
		{DefaultFields(props)} <EditButton />
	</Datagrid>
);

export default DiscountDataGrid;
