import DeviceModelIcon from '@material-ui/icons/DevicesOther';
import DeviceModelList from './DeviceModelList';
import DeviceModelShow from './DeviceModelShow';
import DeviceModelEdit from './DeviceModelEdit';
import DeviceModelCreate from './DeviceModelCreate';

export default {
	list: DeviceModelList,
	show: DeviceModelShow,
	edit: DeviceModelEdit,
	icon: DeviceModelIcon,
	create: DeviceModelCreate,
	name: 'deviceModel'
};
