import React from 'react';
import { SelectField } from 'react-admin';

const TaskStateField = (props) => {
	return (
		<SelectField
			choices={[
				{ id: 0, name: 'Created' },
				{ id: 1, name: 'Received' },
				{ id: 9, name: 'Cancelled' },
				{ id: 10, name: 'Completed' }
			]}
			{...props}
		/>
	);
};

TaskStateField.defaultProps = {
	addLabel: true
};

export default TaskStateField;
