import React from 'react';
import { Datagrid, TextField, BooleanField, EditButton, NumberField } from 'react-admin';

import { ReferenceField, DefaultFields, CompanyReferenceField } from '../customFields';

const CompanyAmountTypeDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<ReferenceField source="amountTypeId" reference="amountType">
			<TextField source="name" />
		</ReferenceField>
		<BooleanField source="isCrossChecked" />
		<BooleanField source="opensCashDrawer" />
		<BooleanField source="isVisible" />
		<TextField source="name" />
		<NumberField source="index" />
		{CompanyReferenceField(props)}
		{DefaultFields(props)}
		<EditButton />
	</Datagrid>
);

export default CompanyAmountTypeDataGrid;
