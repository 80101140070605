import React from 'react'
import {
    Create,
    TextInput,
    BooleanInput,
    SimpleForm,
    number
}
    from 'react-admin'

import DeviceBrandTitle from './DeviceBrandTitle';

export default function DeviceBrandCreate(props) {
    return (
        <Create title={<DeviceBrandTitle />} {...props}>
            <SimpleForm>
                <TextInput source="index" validate={number()} />
                <TextInput source="name" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
    )
}
