import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { translate, DashboardMenuItem, MenuItemLink, Responsive } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

//menu-accounts
import accounts from '../accounts/index';
import companies from '../companies/index';
import shops from '../shops/index';
import registers from '../registers/index';
import employees from '../employees/index';
import roleMappings from '../roleMappings';
//menu-operation
import shifts from '../shifts/index';
//menu-products
import products from '../products/index';
import outputCenters from '../outputCenters/index';
import addonSets from '../addonSets';
import addons from '../addons';
import productAddonSets from '../productAddonSets';
//menu-devices
import deviceTypes from '../deviceTypes/index';
import deviceBrands from '../deviceBrands/index';
import deviceModels from '../deviceModels/index';
import devices from '../devices/index';
import deviceTasks from '../deviceTasks/index';
//menu-payments
import amountTypes from '../amountTypes/index';
import revenueCenters from '../revenueCenters/index';
import companyAmountTypes from '../companyAmountTypes/index';
import discounts from '../discounts';
//menu-setup
import unitTypes from '../unitTypes/index';
import quickKeyFunctions from '../quickKeyFunctions/index';

import SubMenu from './SubMenu';
import shopRevenueCenters from '../shopRevenueCenters';
import exchangeRates from '../exchangeRates';

class Menu extends Component {
	state = {
		menuCatalog: false,
		menuSales: false,
		menuCustomers: false
	};

	static propTypes = {
		onMenuClick: PropTypes.func,
		logout: PropTypes.object
	};

	handleToggle = (menu) => {
		this.setState((state) => ({ [menu]: !state[menu] }));
	};

	render() {
		const { onMenuClick, open, logout, translate } = this.props;

		const ResourceMenuItem = (resource) => {
			return (
				<MenuItemLink
					style={{ height: '100%', textWrap: 'wrap' }}
					to={`/${resource.name}`}
					primaryText={translate(`resources.${resource.name}.name`, {
						smart_count: 2
					})}
					leftIcon={<resource.icon />}
					onClick={onMenuClick}
				/>
			);
		};

		return (
			<div style={{'paddingBottom': 32}}>
				{' '}
				<DashboardMenuItem onClick={onMenuClick} />
				<SubMenu
					handleToggle={() => this.handleToggle('menuAccount')}
					isOpen={this.state.menuAccount}
					sidebarIsOpen={open}
					name="pos.menu.account"
				// icon={<accounts.icon />}
				>
					{ResourceMenuItem(accounts)}
					{ResourceMenuItem(companies)}
					{ResourceMenuItem(shops)}
					{ResourceMenuItem(registers)}
					{ResourceMenuItem(employees)}
					{ResourceMenuItem(roleMappings)}
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuOperation')}
					isOpen={this.state.menuOperation}
					sidebarIsOpen={open}
					name="pos.menu.operation"
				// icon={<accounts.icon />}
				>
					{ResourceMenuItem(shifts)}
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuProduct')}
					isOpen={this.state.menuProduct}
					sidebarIsOpen={open}
					name="pos.menu.product"
				// icon={<products.icon />}
				>
					{ResourceMenuItem(products)}
					{ResourceMenuItem(outputCenters)}
					{ResourceMenuItem(addonSets)}
					{ResourceMenuItem(addons)}
					{ResourceMenuItem(productAddonSets)}
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuDevice')}
					isOpen={this.state.menuDevice}
					sidebarIsOpen={open}
					name="pos.menu.device"
				// icon={<deviceTypes.icon />}
				>
					{ResourceMenuItem(deviceTypes)}
					{ResourceMenuItem(deviceBrands)}
					{ResourceMenuItem(deviceModels)}
					{ResourceMenuItem(devices)}
					{ResourceMenuItem(deviceTasks)}
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuPayment')}
					isOpen={this.state.menuPayment}
					sidebarIsOpen={open}
					name="pos.menu.payment"
				// icon={<deviceTypes.icon />}
				>
					{ResourceMenuItem(amountTypes)}
					{ResourceMenuItem(companyAmountTypes)}
					{ResourceMenuItem(revenueCenters)}
					{ResourceMenuItem(discounts)}
					{ResourceMenuItem(shopRevenueCenters)}
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuSetup')}
					isOpen={this.state.menuSetup}
					sidebarIsOpen={open}
					name="pos.menu.setup"
				// icon={<SettingsIcon />}
				>
					{ResourceMenuItem(quickKeyFunctions)}
					{ResourceMenuItem(unitTypes)}
					{ResourceMenuItem(exchangeRates)}
				</SubMenu>
				<Responsive
					xsmall={
						<MenuItemLink
							to="/configuration"
							primaryText={translate('pos.configuration')}
							leftIcon={<SettingsIcon />}
							onClick={onMenuClick}
						/>
					}
					medium={null}
				/>
				<Responsive
					small={logout}
					medium={null} // Pass null to render nothing on larger devices
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	open: state.admin.ui.sidebarOpen,
	theme: state.theme,
	locale: state.i18n.locale
});

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate);

export default enhance(Menu);
