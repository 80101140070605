import React from 'react';
import { Admin, Resource } from 'react-admin';
import Dashboard from './dashboard/Dashboard';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

import { PayPadLayout } from './customLayout';

import accounts from './accounts/index';
import companies from './companies/index';
import shops from './shops/index';
import registers from './registers/index';
import employees from './employees/index';
import products from './products/index';
import unitTypes from './unitTypes/index';
import deviceTypes from './deviceTypes/index';
import deviceBrands from './deviceBrands/index';
import deviceModels from './deviceModels/index';
import devices from './devices/index';
import deviceTasks from './deviceTasks/index';
import amountTypes from './amountTypes/index';
import revenueCenters from './revenueCenters/index';
import outputCenters from './outputCenters/index';
import quickKeyFunctions from './quickKeyFunctions/index';
import companyAmountTypes from './companyAmountTypes/index';
import roleMappings from './roleMappings';
import addonSets from './addonSets';
import addons from './addons';
import productAddonSets from './productAddonSets';
import discounts from './discounts';
import shifts from './shifts';
import openOrders from './openOrders';
import exchangeRates from './exchangeRates';

import englishMessages from './i18n/en';
import shopRevenueCenters from './shopRevenueCenters';

const i18nProvider = (locale) => {
	if (locale === 'tr') {
		return import('./i18n/tr').then((messages) => messages.default);
	}

	// Always fallback on english
	return englishMessages;
};

const App = () => (
	<Admin
		locale="en"
		dashboard={Dashboard}
		authProvider={authProvider}
		dataProvider={dataProvider}
		i18nProvider={i18nProvider}
		appLayout={PayPadLayout}
	>
		<Resource {...accounts} />
		<Resource {...roleMappings} />
		<Resource {...companies} />
		<Resource {...shops} />
		<Resource {...registers} />
		<Resource {...employees} />
		<Resource {...products} />
		<Resource {...unitTypes} />
		<Resource {...deviceTypes} />
		<Resource {...deviceBrands} />
		<Resource {...deviceModels} />
		<Resource {...devices} />
		<Resource {...amountTypes} />
		<Resource {...revenueCenters} />
		<Resource {...shopRevenueCenters} />
		<Resource {...outputCenters} />
		<Resource {...quickKeyFunctions} />
		<Resource {...companyAmountTypes} />
		<Resource {...addonSets} />
		<Resource {...addons} />
		<Resource {...productAddonSets} />
		<Resource {...discounts} />
		<Resource {...deviceTasks} />
		<Resource {...shifts} />
		<Resource {...openOrders} />
		<Resource {...exchangeRates} />
		<Resource name="roles" />
		<Resource name="quantityType" />
	</Admin>
);

export default App;
