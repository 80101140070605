import ShopIcon from '@material-ui/icons/Store';
import ShopList from './ShopList';
import ShopShow from './ShopShow';
import ShopEdit from './ShopEdit';

export default {
	list: ShopList,
	show: ShopShow,
	edit: ShopEdit,
	icon: ShopIcon,
	name: 'shop'
};
