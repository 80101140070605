import React from 'react';
import { Edit, BooleanInput, NumberInput, TextInput, FormTab } from 'react-admin';

import { TabbedForm } from '../customViews';
import { NameAutocompleteInput } from '../customInputs';

import AddonTitle from './AddonTitle';

const AddonEdit = (props) => (
	<Edit title={<AddonTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<NameAutocompleteInput source="addonSetId" reference="addonSet" />
				<TextInput source="name" />
				<NumberInput source="amount" />
				<NumberInput source="index" />
				<BooleanInput source="active" defaultValue={true} />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default AddonEdit;
