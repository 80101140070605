import React, { Fragment } from 'react';
import { change } from 'redux-form';
import {
	Create,
	BooleanInput,
	ReferenceInput,
	AutocompleteInput,
	FormTab,
	FormDataConsumer,
	REDUX_FORM_NAME
} from 'react-admin';

import { TabbedForm, Aside } from '../customViews';
import { NameAutocompleteInput } from '../customInputs';
import ProductAddonSetTitle from './ProductAddonSetTitle';

const redirect = (basePath, id, data) => `/addonSet/${data.addonSetId}/show/products`;

const ProductAddonSetCreate = (props) => (
	<Create
		title={<ProductAddonSetTitle />}
		aside={
			<Aside
				title="Tips"
				message="First select a company, AddonSets and Products will be filtered for that company"
			/>
		}
		{...props}
	>
		<TabbedForm redirect={redirect}>
			<FormTab label="Details">
				<NameAutocompleteInput source="companyId" reference="company" />
				<FormDataConsumer>
					{({ formData, dispatch, ...rest }) => (
						<Fragment>
							<ReferenceInput
								source="addonSetId"
								reference="addonSet"
								allowEmpty
								filterToQuery={(searchText) => ({ name: searchText, companyId: formData.companyId })}
								filter={{ companyId: formData.companyId, active: true }}
								onChange={(value) => dispatch(change(REDUX_FORM_NAME, 'company', null))}
								{...rest}
							>
								<AutocompleteInput optionText="name" />
							</ReferenceInput>

							<ReferenceInput
								source="productId"
								reference="product"
								allowEmpty
								filterToQuery={(searchText) => ({
									active: true,
									name: searchText,
									companyId: formData.companyId,
									useLike: [ 'name' ]
								})}
								filter={{ companyId: formData.companyId, active: true }}
								{...rest}
							>
								<AutocompleteInput optionText="name" />
							</ReferenceInput>
						</Fragment>
					)}
				</FormDataConsumer>

				<BooleanInput source="active" defaultValue={true} />
			</FormTab>
		</TabbedForm>
	</Create>
);

export default ProductAddonSetCreate;
