import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField } from 'ra-core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

export class JsonInput extends Component {
	static propTypes = {
		addLabel: PropTypes.bool.isRequired,
		classes: PropTypes.object,
		input: PropTypes.object,
		label: PropTypes.string,
		meta: PropTypes.object,
		options: PropTypes.object,
		source: PropTypes.string,
		toolbar: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.bool,
			PropTypes.shape({
				container: PropTypes.array,
				handlers: PropTypes.object
			})
		]),
		fullWidth: PropTypes.bool
	};

	static defaultProps = {
		addLabel: true,
		options: {}, // Quill editor options
		record: {},
		toolbar: true,
		fullWidth: true
	};

	render() {
		const { source, record, defaultValue, input } = this.props;
		const { touched, error, helperText = false } = this.props.meta;
		return (
			<FormControl error={!!(touched && error)} fullWidth={this.props.fullWidth}>
				{
					<JsonEditor
						value={record[source] || defaultValue}
						mode="tree"
						onChange={(value) => input.onChange(value)}
					/>
				}

				{touched &&
				error && (
					<FormHelperText error className="ra-rich-text-input-error">
						{error}
					</FormHelperText>
				)}
				{helperText && <FormHelperText>{helperText}</FormHelperText>}
			</FormControl>
		);
	}
}

const JsonInputWithField = addField(JsonInput);

JsonInputWithField.defaultProps = {
	addLabel: true,
	fullWidth: true
};
export default JsonInputWithField;
