import React from 'react'
import {
    Create,
    TextInput,
    BooleanInput,
    SimpleForm,
    number,
}
    from 'react-admin'

import AmountTypeTitle from './AmountTypeTitle';

export default function AmountTypeCreate(props) {
    return (
        <Create title={<AmountTypeTitle />} {...props}>
            <SimpleForm>
                <TextInput source="index" validate={number()} />
                <TextInput source="name" />
                <TextInput source="currencyCode" />
                <TextInput source="ingenicoAppId" reference="ingenicoApps" />
                <BooleanInput source="isDefault" />
                <BooleanInput source="isPaymentType" />
                <BooleanInput source="isCrossChecked" />
                <BooleanInput source="isCreditCard" />
                <BooleanInput source="isTicket" />
                <BooleanInput source="isCash" />
                <BooleanInput source="isOpeningAmount" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
    )
}
