import englishMessages from 'ra-language-english';

const messages = {
	...englishMessages,
	action: {
		resetPassword: 'Reset Password',
		sendShiftEmail: 'Send Email',
	},
	message: {
		actionSuccess: 'Action completed successfully'
	},
	pos: {
		search: 'Search',
		configuration: 'Configuration',
		menu: {
			account: 'Account',
			operation: 'Operation',
			product: 'Product',
			device: 'Device',
			payment: 'Payment',
			setup: 'Setup'
		},
		dashboard: {
			monthlyRevenue: 'Monthly Revenue',
			newOrders: 'New Orders',
			latestFiveAccount: 'Latest Five Account',
			latestFiveCompany: 'Latest Five Company',
			latestFiveShop: 'Latest Five Shop',
			latestFiveDevice: 'Latest Five Device'
		}
	},
	resources: {
		account: {
			name: 'Account |||| Accounts',
			fields: {
				fullName: 'Full Name',
				type: 'Type',
				phoneNumber: 'Phone Number',
				balance: 0,
				settings: 'Settings',
				username: 'User Name',
				email: 'Email',
				firstName: 'First Name',
				lastName: 'Last Name'
			}
		},
		roleMappings: {
			name: 'Role Mapping |||| Role Mappings',
			fields: {
				principalId: 'User'
			}
		},
		company: {
			name: 'Company |||| Companies',
			fields: {
				name: 'Name',
				logo: 'Logo',
				settings: 'Settings',
				integrationSettings: 'Integration Settings'
			}
		},
		shop: {
			name: 'Shop |||| Shops',
			fields: {
				name: 'Name',
				logo: 'Logo',
				customersActive: 'Customers Active',
				customersMandatory: 'Customers Mandatory',
				stampArea: 'Stamp Area',
				paymentMethods: 'Payment Methods',
				settings: 'Settings',
				serverSettings: 'Server Settings',
				integrationSettings: 'Integration Settings',
				location: 'Location',
				city: 'City',
				postcode: 'Postcode',
				address: 'Address'
			}
		},
		employee: {
			name: 'Employee |||| Employees',
			fields: {
				checkedIn: 'Checked In',
				isDefault: 'Default',
				managesEmployees: 'Manage Employees',
				managesProducts: 'Manage Products',
				pinCode: 'PIN',
				firstName: 'First Name',
				lastName: 'Last Name',
				fullName: 'Full Name '
			}
		},
		product: {
			name: 'Product |||| Products',
			fields: {
				price: 'Price',
				abbreviation: null,
				criticalStock: 'Critical Stock',
				manageStock: 'Manage Stock',
				kitchenProduct: 'Kitchen Product',
				vat: 'VAT',
				barcode: 'Barcode',
				erpcode: 'ERP Code',
				erpType: 'ERP Typr',
				displayName: 'Display Name',
				info: 'Info',
				barProduct: 'Bar Product',
				singular: 'Singular',
				isMandatory: 'Mandatory',
				name: 'Name'
			}
		},
		unitType: {
			name: 'Unit Type |||| Unit Types',
			fields: {
				name: 'Name',
				minQuantity: 'Min Qty',
				maxQuantity: 'Max Qty',
				isQuantityRequired: 'Quantity Required'
			}
		},
		deviceType: {
			name: 'Device Type |||| Device Types',
			fields: {
				name: 'Name'
			}
		},
		deviceBrand: {
			name: 'Device Brand |||| Device Brands',
			fields: {
				name: 'Name'
			}
		},
		deviceModel: {
			name: 'Device Model |||| Device Models',
			fields: {
				name: 'Name',
				modelType: null
			}
		},
		device: {
			name: 'Device |||| Devices',
			fields: {
				name: 'Name',
				serialNumber: 'Serial Number',
				isSimulator: 'Simulator',
				osName: 'OS Name',
				osVersion: 'OS Version',
				appVersion: 'App Version',
				ipAddress: 'IP Address',
				macAddress: 'MAC Address',
				properties: 'Properties',
				backupDeviceId: 'Backup Device Id'
			}
		},
		amountType: {
			name: 'Amount Type |||| Amount Types',
			fields: {
				name: 'Name',
				currencyCode: 'Currency',
				isDefault: 'Default',
				isPaymentType: 'Payment Type',
				isCrossChecked: 'Cross Checked',
				isCreditCard: 'Credit Card',
				isTicket: 'Ticket',
				isCash: 'Cash',
				isOpeningAmount: 'Opening Amount',
				ingenicoAppId: 'Ingenico AppId'
			}
		},
		revenueCenter: {
			name: 'Revenue Center |||| Revenue Centers',
			fields: {
				name: 'Name',
				isDefault: 'Default',
				isDelivery: 'Delivery',
				isTable: 'Table',
				isSelfServis: 'Self Service'
			}
		},
		shopRevenueCenter: {
			name: 'Shop Revenue Center |||| Shop Revenue Centers',
			fields: {
				name: 'Name',
				revenueCenter: 'Revenue Center',
				shop: 'Shop',
				index: 'Index'
			}
		},
		outputCenter: {
			name: 'Output Center |||| Output Centers',
			fields: {
				name: 'Name',
				isCheckout: 'Checkout',
				isGo: 'Go'
			}
		},
		quickKeyFunction: {
			name: 'QuickKey Function |||| QuickKey Functions',
			fields: {
				name: 'Name',
				function: 'Function'
			}
		},
		companyAmountType: {
			name: 'Company Amount Type |||| Company Amount Types',
			fields: {
				name: 'Name',
				isCrossChecked: 'Cross Checked',
				isVisible: 'Visible',
				opensCashDrawer: 'Opens Cash Drawer'
			}
		},
		addonSet: {
			name: 'Addon Set |||| Addon Sets',
			fields: {
				name: 'Name',
				isMultiSelect: 'Multi Select'
			}
		},
		addon: {
			name: 'Addon |||| Addons',
			fields: {
				name: 'Name'
			}
		},
		productAddonSet: {
			name: 'Product Addon Set |||| Product Addon Sets',
			fields: {
				name: 'Name'
			}
		},
		register: {
			name: 'Register |||| Registers',
			fields: {
				name: 'Name',
				deviceId: 'Unique Id'
			}
		},
		discount: {
			name: 'Discount |||| Discounts',
			fields: {
				name: 'Name',
				code: 'Code'
			}
		},
		deviceTask: {
			name: 'Device Task |||| Device Tasks',
			fields: {
				name: 'Name',
				code: 'Code'
			}
		},
		shift: {
			name: 'Shift |||| Shifts',
			fields: {
				name: 'Name'
			}
		},
		exchangeRate: {
			name: 'Exchange Rate |||| Exchange Rates',
			fields: {
				currencyDate: 'Currency Date',
				currencyCode: 'Currency Code',
				currencyRate: 'Currency Rate', 
			}
		},
	}
};

const process = (msgs) => {
	if (msgs && msgs.resources) {
		Object.keys(msgs.resources).forEach((k) => {
			msgs.resources[k].fields.createdAt = 'Created At';
			msgs.resources[k].fields.updatedAt = 'Updated At';
		});
	}

	return msgs;
};

export default process(messages);
