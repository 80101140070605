import React from 'react';
import { Show, TextField, NumberField, TabbedShowLayout, Tab, ReferenceManyField } from 'react-admin';

import { ReferenceField, DefaultFields, DateField } from '../customFields';

import ShiftTitle from './ShiftTitle';
import ShiftSendReportButton from './ShiftSendReportButton';

import OpenOrderDataGrid from '../openOrders/OpenOrderDataGrid';

export const ShiftShow = (props) => (
	<Show title={<ShiftTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<DateField source="openingDate" />
				<ReferenceField source="whoOpenedId" reference="employee">
					<TextField source="fullName" />
				</ReferenceField>
				<DateField source="closingDate" />
				<ReferenceField source="whoClosedId" reference="employee">
					<TextField source="fullName" />
				</ReferenceField>
				<NumberField source="openingAmount" />
				<TextField source="comment" />
				<NumberField source="zNo" />
				{DefaultFields(props)}
				<ShiftSendReportButton />
			</Tab>
			<Tab label="Open Orders" path="openOrders">
				<ReferenceManyField
					reference="openOrder"
					target="shiftId"
					sort={{ field: 'createdAt', order: 'DESC' }}
					filter={{ active: true }}
					addLabel={false}
					perPage={500}
				>
					<OpenOrderDataGrid />
				</ReferenceManyField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ShiftShow;
