import React from 'react'
import {
    Create,
    TextInput,
    BooleanInput,
    SimpleForm,
    number,
}
    from 'react-admin'

import RevenueCenterTitle from './RevenueCenterTitle';

export default function RevenueCenterCreate(props) {
    return (
        <Create title={<RevenueCenterTitle />} {...props}>
            <SimpleForm>
                <TextInput source="index" validate={number()} />
                <TextInput source="name" />
                <BooleanInput source="isDefault" />
                <BooleanInput source="isDelivery" />
                <BooleanInput source="isTable" />
                <BooleanInput source="isSelfService" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
    )
}
