import React from 'react';
import { Edit, TextInput, BooleanInput, NumberInput, DisabledInput, FormTab } from 'react-admin';

import { TabbedForm } from '../customViews';
import QuickKeyFunctionTitle from './QuickKeyFunctionTitle';

const QuickKeyFunctionEdit = (props) => (
	<Edit title={<QuickKeyFunctionTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="name" />
				<NumberInput source="index" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
				<DisabledInput source="createdBy" />
				<DisabledInput source="updatedBy" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default QuickKeyFunctionEdit;
