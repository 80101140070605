import React from 'react';
import { Edit, TextInput, BooleanInput, NumberInput, FormTab } from 'react-admin';

import { TabbedForm } from '../customViews';
import { NameAutocompleteInput } from '../customInputs';
import DiscountTitle from './DiscountTitle';
import DiscountTypeInput from './DiscountTypeInput';

const DiscountEdit = (props) => (
	<Edit title={<DiscountTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<NameAutocompleteInput source="companyId" reference="company" />
				<TextInput source="code" />
				<TextInput source="name" />
				<DiscountTypeInput />
				<NumberInput source="amount" />
				<BooleanInput source="active" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default DiscountEdit;
