import React from 'react'
import {
    Create,
    TextInput,
    BooleanInput,
    SimpleForm,
    number,
}
    from 'react-admin'

import QuickKeyFunctionTitle from './QuickKeyFunctionTitle';

export default function QuickKeyFunctionCreate(props) {
    return (
        <Create title={<QuickKeyFunctionTitle />} {...props}>
            <SimpleForm>
                <TextInput source="index" validate={number()} />
                <TextInput source="name" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
    )
}
