import React from 'react';
import { Edit, TextField, BooleanInput, NumberInput, DisabledInput, FormTab, TextInput, DateInput } from 'react-admin';

import { TabbedForm } from '../customViews';
import ExchangeRateTitle from './ExchangeRateTitle';

const ExchangeRateEdit = (props) => (
    <Edit title={<ExchangeRateTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Details">
                <DisabledInput source="id" />
                <DateInput source="currencyDate" />
                <TextInput source="currencyCode" />
                <NumberInput source="currencyRate" />
                <DisabledInput source="sourceId" />
                <BooleanInput source="active" />
                <DisabledInput source="createdAt" />
                <DisabledInput source="updatedAt" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ExchangeRateEdit;
