import React from 'react';
import { SelectInput } from 'react-admin';

const TaskStateInput = (props) => {
	return (
		<SelectInput
			choices={[
				{ id: 0, name: 'Created' },
				{ id: 1, name: 'Received' },
				{ id: 9, name: 'Cancelled' },
				{ id: 10, name: 'Completed' }
			]}
			defaultValue={0}
			{...props}
		/>
	);
};

export default TaskStateInput;
