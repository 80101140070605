import React, {  } from 'react';
import {
	List,
	TextInput,
	BooleanInput,
	Filter} from 'react-admin';

import EmployeeDataGrid from './EmployeeDataGrid';
import { NameAutocompleteInput } from '../customInputs';

const EmployeeFilter = (props) => {
	return (
		<Filter {...props}>
			<TextInput source="firstName" alwaysOn resettable />
			<TextInput source="lastName" alwaysOn resettable />
			<NameAutocompleteInput source="shopId" reference="shop" {...props} alwaysOn />
			<BooleanInput source="active" alwaysOn />
		</Filter>
	)
};

export default function EmployeeList(props) {

	return (
		<List
			filters={<EmployeeFilter />}
			filterDefaultValues={{ active: true }}
			sort={{ field: 'createdAt', order: 'DESC' }}
			bulkActionButtons={false}
			{...props}
		>
			<EmployeeDataGrid />
		</List>
	)
}



