import React from 'react';
import { List, Datagrid, BooleanField, EditButton, TextInput, BooleanInput, Filter } from 'react-admin';

import { LookupFields } from '../customFields';

const RevenueCenterFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const RevenueCenterDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		{LookupFields(props)}
		<BooleanField source="isDefault" />
		<BooleanField source="isDelivery" />
		<BooleanField source="isTable" />
		<BooleanField source="isSelfService" />

		<EditButton />
	</Datagrid>
);

const RevenueCenterList = (props) => (
	<List
		filters={<RevenueCenterFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<RevenueCenterDataGrid />
	</List>
);

export default RevenueCenterList;
