import React from 'react';
import { Edit, TextInput, BooleanInput, DisabledInput, FormTab, number } from 'react-admin';

import { TabbedForm } from '../customViews';
import DeviceBrandTitle from './DeviceBrandTitle';

export const DeviceBrandEdit = (props) => (
	<Edit title={<DeviceBrandTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="index" validate={number()} />
				<TextInput source="name" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
				<DisabledInput source="createdBy" />
				<DisabledInput source="updatedBy" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default DeviceBrandEdit;
