import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const ShopReferenceField = (props) => {
	// return window.location.href.includes('show') ? null : (
	// 	<ReferenceField source="shopId" reference="shop">
	// 		<TextField source="name" />
	// 	</ReferenceField>
	// );
	return (
		<ReferenceField source="shopId" reference="shop">
			<TextField source="name" />
		</ReferenceField>
	);
};

export default ShopReferenceField;
