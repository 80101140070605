import React from 'react';
import { Show, TextField, BooleanField, TabbedShowLayout, Tab } from 'react-admin';

import { DateField } from '../customFields';
import DeviceBrandTitle from './DeviceBrandTitle';

const DeviceBrandShow = (props) => (
	<Show title={<DeviceBrandTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="index" />
				<TextField source="name" />
				<BooleanField source="active" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
				<TextField source="createdBy" />
				<TextField source="updatedBy" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default DeviceBrandShow;
