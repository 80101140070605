import React from 'react';
import { Datagrid, TextField, NumberField, EditButton } from 'react-admin';

import { DefaultFields, ReferenceField, DateField, ShopReferenceField } from '../customFields';
import ShiftSendReportButton from './ShiftSendReportButton';

const ShiftDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<DateField source="openingDate" />
		<ReferenceField source="whoOpenedId" reference="employee">
			<TextField source="fullName" />
		</ReferenceField>
		<DateField source="closingDate" />
		<ReferenceField source="whoClosedId" reference="employee">
			<TextField source="fullName" />
		</ReferenceField>
		<NumberField source="openingAmount" />
		<TextField source="comment" />
		<NumberField source="zNo" />
		{ShopReferenceField(props)}
		{DefaultFields(props)}
		<EditButton />
		<ShiftSendReportButton />
	</Datagrid>
);

export default ShiftDataGrid;
