import RevenueCenterIcon from '@material-ui/icons/EventSeat';
import ShopRevenueCenterShow from './ShopRevenueCenterShow';
import ShopRevenueCenterList from './ShopRevenueCenterList';
import ShopRevenueCenterEdit from './ShopRevenueCenterEdit';
import ShopRevenueCenterCreate from './ShopRevenueCenterCreate';

export default {
	list: ShopRevenueCenterList,
	show: ShopRevenueCenterShow,
	create: ShopRevenueCenterCreate,
	edit: ShopRevenueCenterEdit,
	icon: RevenueCenterIcon,
	name: 'shopRevenueCenter'
};
