import React from 'react';
import { Edit, TextInput, DisabledInput, FormTab, number } from 'react-admin';

import { TabbedForm } from '../customViews';
import ShiftTitle from './ShiftTitle';

export const ShiftEdit = (props) => (
	<Edit title={<ShiftTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<DisabledInput source="openingDate" />
				<DisabledInput source="closingDate" />
				<DisabledInput source="openingAmount"  validate={[number()]} />
				<TextInput source="comment" />
				<DisabledInput source="zNo" />
			</FormTab>
		</TabbedForm>
	</Edit>
);
export default ShiftEdit;
