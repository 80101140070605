// in src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

import { API_URL, TOKEN_KEY, USER_KEY, APP_ID } from './constants';

export default (type, params) => {
	// called when the user attempts to log in
	if (type === AUTH_LOGIN) {
		const { username, password } = params;
		const request = new Request(`${API_URL}/account/login`, {
			method: 'POST',
			body: JSON.stringify({ username, password }),
			headers: new Headers({
				'Content-Type': 'application/json',
				'PayPad-App-Id': APP_ID
			})
		});
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((authResponse) => {
				localStorage.setItem(TOKEN_KEY, authResponse.token.id);
				localStorage.setItem(USER_KEY, authResponse.fullName);
			});
	}
	// called when the user clicks on the logout button
	if (type === AUTH_LOGOUT) {
		localStorage.removeItem(TOKEN_KEY);
		localStorage.removeItem(USER_KEY);
		return Promise.resolve();
	}
	// called when the API returns an error
	if (type === AUTH_ERROR) {
		const { status } = params;
		if (status === 401 || status === 403) {
			localStorage.removeItem(TOKEN_KEY);
			localStorage.removeItem(USER_KEY);
			return Promise.reject();
		}
		return Promise.resolve();
	}
	// called when the user navigates to a new location
	if (type === AUTH_CHECK) {
		return localStorage.getItem(TOKEN_KEY) ? Promise.resolve() : Promise.reject();
	}
	return Promise.reject('Unknown method');
};
