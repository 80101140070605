import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab } from 'react-admin';

import { DateField } from '../customFields';
import RevenueCenterTitle from './RevenueCenterTitle';

const RevenueCenterShow = (props) => (
	<Show title={<RevenueCenterTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<NumberField source="index" />
				<BooleanField source="isDefault" />
				<BooleanField source="isDelivery" />
				<BooleanField source="isTable" />
				<BooleanField source="isSelfService" />
				<BooleanField source="active" />
				<DateField source="createdAt"  />
				<DateField source="updatedAt"  />
				<TextField source="createdBy" />
				<TextField source="updatedBy" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default RevenueCenterShow;
