import React from 'react';
import { List, BooleanInput, Filter, TextInput } from 'react-admin';

import AddonDataGrid from './AddonDataGrid';

const AddonFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const AddonList = (props) => (
	<List
		filters={<AddonFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<AddonDataGrid />
	</List>
);

export default AddonList;
