import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab } from 'react-admin';

import { DateField } from '../customFields';
import EmployeeTitle from './EmployeeTitle';

export const EmployeeShow = (props) => (
	<Show title={<EmployeeTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="firstName" />
				<TextField source="lastName" />
				<TextField source="fullName" />
				<BooleanField source="checkedIn" />
				<BooleanField source="isDefault" />
				<BooleanField source="managesEmployees" />
				<BooleanField source="managesProducts" />
				<DateField source="pinCode" />
				<NumberField source="type" />
				<BooleanField source="active" />
				<DateField source="createdAt"  />
				<DateField source="updatedAt"  />
				<TextField source="createdBy" />
				<TextField source="updatedBy" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default EmployeeShow;
