import React from 'react';
import { List, BooleanInput, Filter } from 'react-admin';

import ProductAddonSetDataGrid from './ProductAddonSetDataGrid';

const ProductAddonSetFilter = (props) => (
	<Filter {...props}>
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const ProductAddonSetList = (props) => (
	<List
		filters={<ProductAddonSetFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<ProductAddonSetDataGrid />
	</List>
);

export default ProductAddonSetList;
