import React from 'react';
import { Datagrid, TextField } from 'react-admin';

import { ReferenceField, DefaultFields } from '../customFields';

const ProductAddonSetDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<ReferenceField source="productId" reference="product">
			<TextField source="name" />
		</ReferenceField>
		<ReferenceField source="addonSetId" reference="addonSet">
			<TextField source="name" />
		</ReferenceField>
		{DefaultFields(props)}{' '}
	</Datagrid>
);

export default ProductAddonSetDataGrid;
