import AddonIcon from '@material-ui/icons/LinearScale';
import AddonList from './AddonList';
import AddonShow from './AddonShow';
import AddonEdit from './AddonEdit';
import AddonCreate from './AddonCreate';

export default {
	list: AddonList,
	show: AddonShow,
	edit: AddonEdit,
	create: AddonCreate,
	icon: AddonIcon,
	name: 'addon'
};
