import React from 'react';
import { Create, TextInput, BooleanInput, NumberInput, FormTab } from 'react-admin';

import AddonTitle from './AddonTitle';

import { TabbedForm } from '../customViews';
import { NameAutocompleteInput } from '../customInputs';

const redirect = (basePath, id, data) => `/addonSet/${data.addonSetId}/show/addons`;

const AddonCreate = (props) => (
	<Create title={<AddonTitle />} {...props}>
		<TabbedForm redirect={redirect}>
			<FormTab label="Details">
				<NameAutocompleteInput source="addonSetId" reference="addonSet" />
				<TextInput source="name" />
				<NumberInput source="amount" />
				<NumberInput source="index" />
				<BooleanInput source="active" defaultValue={true} />
			</FormTab>
		</TabbedForm>
	</Create>
);

export default AddonCreate;
