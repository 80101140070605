import React from 'react';
import { List, Datagrid, TextField, EditButton, TextInput, BooleanInput, Filter } from 'react-admin';

import { ReferenceField, DefaultFields } from '../customFields';
import { NameAutocompleteInput } from '../customInputs';

const DeviceModelFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<NameAutocompleteInput source="deviceTypeId" reference="deviceType" alwaysOn />
		<NameAutocompleteInput source="deviceBrandId" reference="deviceBrand" alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

export const DeviceModelDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		<ReferenceField source="deviceTypeId" reference="deviceType" linkType="show">
			<TextField source="name" />
		</ReferenceField>
		<ReferenceField source="deviceBrandId" reference="deviceBrand" linkType="show">
			<TextField source="name" />
		</ReferenceField>
		{DefaultFields(props)} <EditButton />
	</Datagrid>
);

export const DeviceModelList = (props) => (
	<List
		filters={<DeviceModelFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<DeviceModelDataGrid />
	</List>
);
export default DeviceModelList;
