import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const CompanyReferenceField = (props) => {
	return window.location.href.includes('show') ? null : (
		<ReferenceField source="companyId" reference="company">
			<TextField source="name" />
		</ReferenceField>
	);
};

export default CompanyReferenceField;
