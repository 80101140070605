import React from 'react';
import { List, TextInput, BooleanInput, Filter } from 'react-admin';

import DiscountDataGrid from './DiscountDataGrid';

import { NameAutocompleteInput } from '../customInputs';

const DiscountFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<NameAutocompleteInput source="companyId" reference="company" />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const DiscountList = (props) => (
	<List
		filters={<DiscountFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<DiscountDataGrid />
	</List>
);

export default DiscountList;
