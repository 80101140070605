import ProductIcon from '@material-ui/icons/ShoppingCart';
import ProductList from './ProductList';
import ProductShow from './ProductShow';
import ProductEdit from './ProductEdit';
//import ProductCreate from './ProductCreate';

export default {
	list: ProductList,
	show: ProductShow,
	edit: ProductEdit,
	icon: ProductIcon,
	name: 'product'
};
