import DeviceTypeIcon from '@material-ui/icons/DeviceHub';
import DeviceTypeList from './DeviceTypeList';
import DeviceTypeShow from './DeviceTypeShow';
import DeviceTypeEdit from './DeviceTypeEdit';
import DeviceTypeCreate from './DeviceTypeCreate';
//import DeviceTypeCreate from './DeviceTypeCreate';

export default {
	list: DeviceTypeList,
	show: DeviceTypeShow,
	edit: DeviceTypeEdit,
	create: DeviceTypeCreate,
	icon: DeviceTypeIcon,
	name: 'deviceType'
};
