import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';
import { Link } from 'react-router-dom';
import CardIcon from './CardIcon';

const styles = {
    main: {
        flex: '1',
    },
    card: {
        overflow: 'inherit',
        textAlign: 'left',
        padding: 16,
        minHeight: '34vh',
    },
};

const CustomLatestList = ({ value, translate, classes, totalTitle, titleFromTranslate, dataCount, linkSource, icon }) => {
    return (
        <div className={classes.main}>
            <CardIcon Icon={icon} bgColor="#31708f" />
            <Card className={classes.card}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'flex-end' }}>
                    <Typography className={classes.title} color="textSecondary">
                        {`Total ${totalTitle}`}
                    </Typography>
                    <Typography variant="headline">
                        {value?.length ?? 0}
                    </Typography>
                </div>
                <Typography style={{ marginTop: 32 }} className={classes.title} color="textSecondary">
                    {translate(titleFromTranslate)}
                </Typography>
                <div style={{ width: '100%', height: 1, backgroundColor: 'lightgray', marginTop: 8, marginBottom: 6 }}></div>
                {value?.slice(0, dataCount).map((v, index) => (
                    <Link key={index}
                        to={{
                            pathname: `/${linkSource}/${v.id}/show`,
                        }}
                        style={{
                            display: 'flex',
                            padding: 4,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            height: 42,
                            borderBottom: 'solid',
                            borderWidth: 0.5,
                            borderColor: 'lightgray',
                            cursor: 'pointer',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}>
                        <div style={{ fontSize: 12 }}>
                            {v?.name || v?.fullName}
                        </div>
                        {v?.email &&
                            <div style={{ fontSize: 14 }}>
                                {v?.email}
                            </div>
                        }
                        {/* {v?.logo && <img src={v.logo} alt={v.name} width={40} height={40} style={{ objectFit: 'contain' }} />} */}
                    </Link>
                ))}
            </Card>
        </div>
    )
};

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(CustomLatestList);
