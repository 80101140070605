import RoleMappingIcon from '@material-ui/icons/AccountBox';
import RoleMappingList from './RoleMappingList';
import RoleMappingShow from './RoleMappingShow';
import RoleMappingEdit from './RoleMappingEdit';
import RoleMappingCreate from './RoleMappingCreate';

export default {
	list: RoleMappingList,
	// show: RoleMappingShow,
	edit: RoleMappingEdit,
	create: RoleMappingCreate,
	icon: RoleMappingIcon,
	name : 'roleMappings'
};
