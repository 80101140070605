import React from 'react';
import {
    List,
    Datagrid,
    BooleanField,
    EditButton,
    TextInput,
    TextField,
    NumberField,
    DateField,
    BooleanInput,
    Filter,
    ReferenceField
} from 'react-admin';
import { NameAutocompleteInput } from '../customInputs';

const ShopRevenueCenterFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
        <NameAutocompleteInput source="shopId" reference="shop" alwaysOn />
        <NameAutocompleteInput source="revenueCenterId" reference="revenueCenter" alwaysOn />
        <BooleanInput source="active" alwaysOn />
    </Filter>
);

const ShopRevenueCenterDataGrid = (props) => (
    <Datagrid rowClick="show" {...props}>
        <NumberField source="index" key="index" />,
        <TextField source="name" key="name" />,
        <ReferenceField source="shopId" reference="shop">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="revenueCenterId" reference="revenueCenter">
            <TextField source="name" />
        </ReferenceField>
        <BooleanField source="active" key="active" />,
        <DateField source="createdAt" key="createdAt" />,
        <DateField source="updatedAt" key="updatedAt" />
        <EditButton />
    </Datagrid>
);

const ShopRevenueCenterList = (props) => (
    <List
        filters={<ShopRevenueCenterFilter />}
        filterDefaultValues={{ active: true }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        bulkActionButtons={false}
        {...props}
    >
        <ShopRevenueCenterDataGrid />
    </List>
);

export default ShopRevenueCenterList;
