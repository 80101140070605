import React from 'react';
import { Create, TextInput, BooleanInput, NumberInput, FormTab } from 'react-admin';

import { ColorInput } from 'react-admin-color-input';

import { TabbedForm } from '../customViews';
import { ReferenceAutocompleteInput } from '../customInputs';

import AddonSetTitle from './AddonSetTitle';

const AddonSetCreate = (props) => (
	<Create title={<AddonSetTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<ReferenceAutocompleteInput source="companyId" reference="company" searchField="name" />
				<TextInput source="name" />
				<NumberInput source="index" />
				<ColorInput source="colorHex" />
				<BooleanInput source="isMultiSelect" />
				<BooleanInput source="active" defaultValue={true} />
			</FormTab>
		</TabbedForm>
	</Create>
);

export default AddonSetCreate;
