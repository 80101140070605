import React from 'react';
import { List, TextInput, BooleanInput, Filter } from 'react-admin';

import ShopDataGrid from './ShopDataGrid';
import { NameAutocompleteInput } from '../customInputs';

const ShopFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<NameAutocompleteInput source="companyId" reference="company" {...props} alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

export const ShopList = (props) => (
	<List
		filters={<ShopFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<ShopDataGrid />
	</List>
);
export default ShopList;
