import UnitTypeIcon from '@material-ui/icons/LinearScale';
import UnitTypeList from './UnitTypeList';
import UnitTypeShow from './UnitTypeShow';
import UnitTypeEdit from './UnitTypeEdit';
import UnitTypeCreate from './UnitTypeCreate';

export default {
	list: UnitTypeList,
	show: UnitTypeShow,
	create: UnitTypeCreate,
	edit: UnitTypeEdit,
	icon: UnitTypeIcon,
	name: 'unitType'
};
