import React from 'react';
import { SelectInput } from 'react-admin';

const TaskTypeInput = (props) => {
	return (
		<SelectInput choices={[ { id: 0, name: 'Update' }, { id: 1, name: 'Popup' } ]} defaultValue={0} {...props} />
	);
};

export default TaskTypeInput;
