import AccountIcon from '@material-ui/icons/Group';
import AccountList from './AccountList';
import AccountShow from './AccountShow';
import AccountEdit from './AccountEdit';
import AccountCreate from './AccountCreate';

export default {
	list: AccountList,
	show: AccountShow,
	edit: AccountEdit,
	create: AccountCreate,
	icon: AccountIcon,
	name: 'account'
};
