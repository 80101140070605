import React from 'react';
import { Datagrid, TextField, BooleanField, EditButton, NumberField } from 'react-admin';

import { ReferenceField,  DefaultFields } from '../customFields';

const ProductDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		<TextField source="displayName" />
		{/* <TextField source="info" /> */}
		<NumberField source="price" />
		<TextField source="abbreviation" />
		<NumberField source="criticalStock" />
		{/* <ReferenceField source="supplierId" reference="suppliers">
			<TextField source="id" />
		</ReferenceField> */}
		{/* <ReferenceField source="colorId" reference="colors">
			<TextField source="id" />
		</ReferenceField> */}
		{/* <ReferenceField source="categoryId" reference="categories">
			<TextField source="id" />
		</ReferenceField> */}

		<NumberField source="vat" />
		{/* <ReferenceField source="unitTypeId" reference="unitTypes">
			<TextField source="id" />
		</ReferenceField> */}
		<TextField source="barcode" />
		<TextField source="erpcode" />
		{/* <TextField source="erpType" /> */}
		<NumberField source="type" />
		<BooleanField source="manageStock" />
		<BooleanField source="kitchenProduct" />
		<BooleanField source="barProduct" />
		<BooleanField source="singular" />
		{/* <TextField source="images" /> */}
		<TextField source="isMandatory" />
		{/* <TextField source="createdBy" />
		<TextField source="updatedBy" /> */}
		<ReferenceField source="companyId" reference="company">
			<TextField source="name" />
		</ReferenceField>
		{/* <ReferenceField source="shopId" reference="shops">
			<TextField source="id" />
		</ReferenceField> */}
		{DefaultFields(props)}		<EditButton />
	</Datagrid>
);

export default ProductDataGrid;
