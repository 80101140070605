import React from 'react';
import { Datagrid, TextField, EditButton } from 'react-admin';

import { ReferenceField, ShopReferenceField, DefaultFields } from '../customFields';

const DeviceDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		<TextField source="serialNumber" />
		<TextField source="osName" />
		<TextField source="osVersion" />
		<TextField source="appVersion" />
		<TextField source="ipAddress" />
		<TextField source="macAddress" />
		<ReferenceField source="deviceModelId" reference="deviceModel" linkType="show">
			<TextField source="name" />
		</ReferenceField>
		{ShopReferenceField(props)}
		{DefaultFields(props)} <EditButton />
	</Datagrid>
);

export default DeviceDataGrid;
