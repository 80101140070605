import React from 'react';
import { List, Datagrid, TextField, NumberField, EditButton, TextInput, BooleanInput, Filter } from 'react-admin';

import { DefaultFields } from '../customFields';

const AccountFilter = (props) => (
	<Filter {...props}>
		<TextInput source="fullName" alwaysOn />
		<TextInput source="email" alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const AccountList = (props) => (
	<List
		filters={<AccountFilter />}
		filter={{ useLike: ['fullName', 'email'] }}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<Datagrid rowClick="show">
			{/* <TextField source="id" /> */}
			<TextField source="fullName" />
			{/* <EmailField source="email" /> */}
			<TextField source="email" />
			<NumberField source="type" />
			{/* <NumberField source="balance" /> */}
			{/* <TextField source="settings.webSettings.shops" /> */}
			<TextField source="phoneNumber" />
			{/* <TextField source="firstName" /> */}
			{/* <TextField source="lastName" /> */}
			{DefaultFields(props)} <EditButton />
		</Datagrid>
	</List>
);

export default AccountList;
