import React from 'react';
import { Datagrid, TextField, BooleanField, EditButton, NumberField } from 'react-admin';
import { DefaultFields, ShopReferenceField } from '../customFields';

const OutputCenterDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		<NumberField source="index" />
		<BooleanField source="isCheckout" />
		<BooleanField source="isGo" />
		{ShopReferenceField(props)}
		{DefaultFields(props)}
		<EditButton />
	</Datagrid>
);

export default OutputCenterDataGrid;
