import React from 'react'
import {
    Create,
    TextInput,
    BooleanInput,
    SimpleForm,
    number, 
}
    from 'react-admin'

import DeviceModelTitle from './DeviceModelTitle';
import { NameAutocompleteInput } from '../customInputs';

export default function DeviceModelCreate(props) {
    return (
        <Create title={<DeviceModelTitle />} {...props}>
            <SimpleForm>
                <TextInput source="index" validate={number()} />
                <TextInput source="name" />
                <NameAutocompleteInput source="deviceBrandId" reference="deviceBrand" />
                <NameAutocompleteInput source="deviceTypeId" reference="deviceType" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
    )
}
