import React from 'react'
import {
    Create,
    TextInput,
    BooleanInput,
    SimpleForm,
    number
}
    from 'react-admin'

import DeviceTypeTitle from './DeviceTypeTitle';

export default function DeviceTypeCreate(props) {
    return (
        <Create title={<DeviceTypeTitle />} {...props}>
            <SimpleForm>
                <TextInput source="index" validate={number()} />
                <TextInput source="name" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
    )
}
