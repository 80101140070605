import React from 'react';
import { List, TextInput, BooleanInput, Filter } from 'react-admin';

import { NameAutocompleteInput } from '../customInputs';

import RegisterDataGrid from './RegisterDataGrid';

const RegisterFilter = (props) => (
	<Filter {...props}>
		<TextInput source="id" alwaysOn />
		<TextInput source="name" alwaysOn />
		<TextInput source="deviceId" alwaysOn />
		<NameAutocompleteInput source="shopId" reference="shop" alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const RegisterList = (props) => (
	<List
		filters={<RegisterFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<RegisterDataGrid />
	</List>
);

export default RegisterList;
