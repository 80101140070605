import React from 'react';
import { SelectField } from 'react-admin';

const TaskTypeField = (props) => {
	return (
		<SelectField choices={[ { id: 0, name: 'Update' }, { id: 1, name: 'Popup' } ]} defaultValue={0} {...props} />
	);
};

TaskTypeField.defaultProps = {
	addLabel: true
};

export default TaskTypeField;
