import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const JsonField = ({ label, source, record = {} }) => (
	<div>
		<InputLabel>{label}</InputLabel>
		<br/>
		<br/>
		<JsonEditor value={record[source]} mode="view" />
		<br/>
	</div>
);

JsonField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired
};

JsonField.defaultProps = {
	addLabel: false
};
export default JsonField;
