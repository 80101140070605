import React, {  } from 'react';
import {
	List,
	TextInput,
	BooleanInput,
	Filter} from 'react-admin';

import ShiftDataGrid from './ShiftDataGrid';
import { NameAutocompleteInput } from '../customInputs';

const ShiftFilter = (props) => {
	return (<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<NameAutocompleteInput source="shopId" reference="shop" {...props} alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>)
};

export const ShiftList = (props) => (
	<List
		filters={<ShiftFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<ShiftDataGrid />
	</List>
);
export default ShiftList;
