import CompanyAmountTypeIcon from '@material-ui/icons/AttachMoney';
import CompanyAmountTypeList from './CompanyAmountTypeList';
import CompanyAmountTypeShow from './CompanyAmountTypeShow';
import CompanyAmountTypeEdit from './CompanyAmountTypeEdit';
import CompanyAmountTypeCreate from './CompanyAmountTypeCreate';

export default {
	list: CompanyAmountTypeList,
	show: CompanyAmountTypeShow,
	edit: CompanyAmountTypeEdit,
	create: CompanyAmountTypeCreate,
	icon: CompanyAmountTypeIcon,
	name: 'companyAmountType'
};
