import AddonSetIcon from '@material-ui/icons/LinearScale';
import AddonSetList from './AddonSetList';
import AddonSetShow from './AddonSetShow';
import AddonSetEdit from './AddonSetEdit';
import AddonSetCreate from './AddonSetCreate';

export default {
	list: AddonSetList,
	show: AddonSetShow,
	edit: AddonSetEdit,
	create: AddonSetCreate,
	icon: AddonSetIcon,
	name: 'addonSet'
};
