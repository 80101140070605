import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab, ReferenceManyField } from 'react-admin';

import { ReferenceField, DateField } from '../customFields';
import ProductTitle from './ProductTitle';
import ProductAddonSetDataGrid from '../productAddonSets/ProductAddonSetDataGrid';
import { AddNewAddonSetButton } from './AddButtons';

const ProductShow = (props) => (
	<Show title={<ProductTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<TextField source="displayName" />
				<TextField source="info" />
				<NumberField source="price" />
				<TextField source="abbreviation" />
				<NumberField source="criticalStock" />
				{/* <ReferenceField source="supplierId" reference="suppliers">
			<TextField source="id" />
		</ReferenceField> */}
				{/* <ReferenceField source="colorId" reference="colors">
			<TextField source="id" />
		</ReferenceField> */}
				{/* <ReferenceField source="categoryId" reference="categories">
			<TextField source="id" />
		</ReferenceField> */}

				<NumberField source="vat" />
				{/* <ReferenceField source="unitTypeId" reference="unitTypes">
			<TextField source="id" />
		</ReferenceField> */}
				<TextField source="barcode" />
				<TextField source="erpcode" />
				<TextField source="erpType" />
				<NumberField source="type" />
				<BooleanField source="manageStock" />
				<BooleanField source="kitchenProduct" />
				<BooleanField source="barProduct" />
				<BooleanField source="singular" />
				<TextField source="images" />
				<TextField source="isMandatory" />
				{/* <TextField source="createdBy" />
		<TextField source="updatedBy" /> */}
				<ReferenceField source="companyId" reference="company">
					<TextField source="name" />
				</ReferenceField>
				{/* <ReferenceField source="shopId" reference="shops">
			<TextField source="id" />
		</ReferenceField> */}
				<BooleanField source="active" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
			</Tab>
			<Tab label="Addon Sets" path="productAddonSets">
				<ReferenceManyField reference="productAddonSet" target="productId" addLabel={false} perPage={1000}>
					<ProductAddonSetDataGrid />
				</ReferenceManyField>
				<AddNewAddonSetButton />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ProductShow;
