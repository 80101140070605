import React, { Fragment } from 'react';
import {
	Create,
	BooleanInput,
	ReferenceInput,
	NumberInput,
	TextInput,
	LongTextInput,
	SelectInput,
	AutocompleteInput,
	FormTab,
	FormDataConsumer,
	required
} from 'react-admin';

import { JsonInput } from '../customInputs';
import { TabbedForm, Aside } from '../customViews';
import DeviceTaskTitle from './DeviceTaskTitle';
import TaskTypeInput from './TaskTypeInput';
import TaskStateInput from './TaskStateInput';
import { NameAutocompleteInput } from '../customInputs';

const taskType = {
	update: 0,
	popup: 1
};

const validateShopId = [ required() ];
const validateParamsVersion = [ required() ];

const DeviceTaskCreate = (props) => (
	<Create
		title={<DeviceTaskTitle />}
		aside={<Aside title="Tips" message="First select a Shop, Registers will be filtered for that company" />}
		{...props}
	>
		<TabbedForm redirect="list">
			<FormTab label="Details">
				<FormDataConsumer>
					{({ formData, dispatch, ...rest }) => (
						<Fragment>
							<NameAutocompleteInput
								perPage={100}
								source="shopId"
								reference="shop"
								validate={validateShopId}
								{...rest}
							/>
							<ReferenceInput
								perPage={100}
								source="registerId"
								reference="register"
								allowEmpty
								filterToQuery={(searchText) => ({
									active: true,
									isOpen: true,
									name: searchText,
									shopId: formData.shopId,
									useLike: [ 'name' ]
								})}
								filter={{ shopId: formData.shopId, active: true, isOpen: true }}
								{...rest}
							>
								<AutocompleteInput optionText="name" />
							</ReferenceInput>
						</Fragment>
					)}
				</FormDataConsumer>
				<TaskTypeInput source="taskType" />
				<FormDataConsumer>
					{({ formData, dispatch, ...rest }) =>
						formData.taskType === taskType.update && (
							<Fragment>
								<BooleanInput source="paramsForce" />
								<TextInput source="paramsVersion" validate={validateParamsVersion} />
							</Fragment>
						)}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData, dispatch, ...rest }) =>
						formData.taskType === taskType.popup && (
							<Fragment>
								<SelectInput
									source="paramsTrigger"
									choices={[ { id: 0, name: 'App' }, { id: 1, name: 'Order' } ]}
									defaultValue={0}
								/>
								<SelectInput
									source="paramsType"
									choices={[
										{ id: 0, name: 'Info' },
										{ id: 1, name: 'Warning' },
										{ id: 2, name: 'Error' }
									]}
									defaultValue={0}
								/>
								<TextInput source="paramsTitle" />
								<LongTextInput source="paramsMessage" />
							</Fragment>
						)}
				</FormDataConsumer>

				<TaskStateInput source="taskState" />
				<NumberInput source="priority" defaultValue={1} />
				<BooleanInput source="active" defaultValue={true} />
			</FormTab>
			<FormTab label="Parameters" path="parameters">
				<FormDataConsumer>
					{({ formData, dispatch, ...rest }) => (
						<JsonInput
							source="parameters"
							defaultValue={
								formData.taskType === taskType.update ? (
									{
										force: formData.paramsForce,
										url: formData.paramsVersion
											? `itms-services://?action=download-manifest&url=https://s3.eu-central-1.amazonaws.com/kurulum.paypadapp.com/PayPad${formData.paramsVersion.replace(
													'.',
													''
												)}.plist`
											: null,
										version: formData.paramsVersion
									}
								) : (
									{
										trigger: formData.paramsTrigger,
										type: formData.paramsType,
										title: formData.paramsTitle,
										message: formData.paramsMessage,
										actions: null,
										requireResponse: false
									}
								)
							}
						/>
					)}
				</FormDataConsumer>
			</FormTab>
		</TabbedForm>
	</Create>
);

export default DeviceTaskCreate;
