import React from 'react';
import { List, TextInput, BooleanInput, Filter } from 'react-admin';

import CompanyDataGrid from './CompanyDataGrid';

const CompanyFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Name" source="name" alwaysOn />
		<BooleanInput label="Active" source="active" alwaysOn />
	</Filter>
);

const CompanyList = (props) => (
	<List
		filters={<CompanyFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<CompanyDataGrid />
	</List>
);

export default CompanyList;
