import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, DELETE, fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

import { TOKEN_KEY, APP_ID, API_URL, RESET_PASSWORD, SHIFT_SEND_REPORT } from './constants';

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
	const token = localStorage.getItem(TOKEN_KEY);
	const headers = new Headers({
		Authorization: token,
		'PayPad-App-Id': APP_ID
	});

	switch (type) {
		case GET_LIST: {
			const { page, perPage } = params.pagination;
			const { field, order } = params.sort;
			const filterObj = {};
			Object.keys(params.filter).forEach((k) => {
				filterObj[k] =
					params.filter.useLike && params.filter.useLike.includes(k)
						? { ilike: `%${params.filter[k]}%` }
						: params.filter[k];
			});
			delete filterObj.useLike;
			const query = {
				sort: JSON.stringify([ field, order ]),
				range: JSON.stringify([ (page - 1) * perPage, page * perPage - 1 ]),
				filter: JSON.stringify(filterObj)
			};

			//loopback filter model
			// const loopbackFilter = {
			// 	skip: (page - 1) * perPage,
			// 	limit: perPage,
			// 	order: `${field} ${order}`
			// };

			return {
				url: `${API_URL}/${resource}?${stringify(query)}`,
				options: { headers }
			};
		}
		case GET_ONE:
			return {
				url: `${API_URL}/${resource}/${params.id}`,
				options: { headers }
			};
		case GET_MANY: {
			const query = {
				filter: JSON.stringify({ id: params.ids })
			};
			return {
				url: `${API_URL}/${resource}?${stringify(query)}`,
				options: { headers }
			};
		}
		case GET_MANY_REFERENCE: {
			const { page, perPage } = params.pagination;
			const { field, order } = params.sort;
			const query = {
				sort: JSON.stringify([ field, order ]),
				range: JSON.stringify([ (page - 1) * perPage, page * perPage - 1 ]),
				filter: JSON.stringify({ ...params.filter, [params.target]: params.id })
			};
			return {
				url: `${API_URL}/${resource}?${stringify(query)}`,
				options: { headers }
			};
		}
		case UPDATE:
			if (params && params.data && params.data.updatedAt) {
				params.data.updatedAt = new Date();
			}
			return {
				url: `${API_URL}/${resource}/${params.id}`,
				options: { method: 'PATCH', body: JSON.stringify(params.data), headers }
			};
		case CREATE:
			if (params && params.data && params.data.createdAt) {
				params.data.createdAt = new Date();
			}
			if (params && params.data && params.data.updatedAt) {
				params.data.updatedAt = new Date();
			}
			return {
				url: `${API_URL}/${resource}`,
				options: { method: 'PATCH', body: JSON.stringify(params.data), headers }
			};
		case DELETE:
			return {
				url: `${API_URL}/${resource}/${params.id}`,
				options: { method: 'DELETE', headers }
			};
		case RESET_PASSWORD:
			return {
				url: `${API_URL}/${resource}`,
				options: { method: 'POST', body: JSON.stringify(params.data), headers }
			};
		case SHIFT_SEND_REPORT:
			return {
				url: `${API_URL}/${resource}`,
				options: { method: 'POST', body: JSON.stringify(params.data), headers }
			};
		default:
			throw new Error(`Unsupported fetch action type ${type}`);
	}
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
	const { headers, json } = response;
	switch (type) {
		case GET_LIST:
		case GET_MANY_REFERENCE:
			return {
				data: json.map((x) => x),
				total: parseInt(headers.get('content-range').split('/').pop(), 10)
			};
		case CREATE:
			return { data: { ...params.data, id: json.id } };
		default:
			return { data: json };
	}
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
	const { fetchJson } = fetchUtils;
	const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
	return fetchJson(url, options).then((response) =>
		convertHTTPResponseToDataProvider(response, type, resource, params)
	);
};
