import React from 'react'
import {
    Create,
    TextInput,
    BooleanInput,
    SimpleForm,
    number,
}
    from 'react-admin'

import CompanyAmountTypeTitle from './CompanyAmountTypeTitle';
import { NameAutocompleteInput } from '../customInputs';

export default function CompanyAmountTypeCreate(props) {
    return (
        <Create title={<CompanyAmountTypeTitle />} {...props}>
            <SimpleForm>
                <TextInput source="id" />
                <TextInput source="index" validate={number()} />
                <TextInput source="name" />
                <NameAutocompleteInput source="companyId" reference="company" />
                <NameAutocompleteInput source="amountTypeId" reference="amountType" />
                <BooleanInput source="isCrossChecked" />
                <BooleanInput source="opensCashDrawer" />
                <BooleanInput source="isVisible" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
    )
}
