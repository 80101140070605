import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import AddonIcon from '@material-ui/icons/LinearScale';
import ProductIcon from '@material-ui/icons/ShoppingCart';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	button: {
		marginTop: '1em'
	}
};

export const AddNewProductButton = withStyles(styles)(({ classes, record }) => (
	<Button
		className={classes.button}
		variant="raised"
		component={Link}
		to={{
			pathname: '/productAddonSet/create',
			state: { record: { addonSetId: record.id, companyId: record.companyId, active: true } }
		}}
		label="Add Product"
	>
		<ProductIcon />
	</Button>
));

export const AddNewAddonButton = withStyles(styles)(({ classes, record }) => (
	<Button
		className={classes.button}
		variant="raised"
		component={Link}
		to={{
			pathname: '/addon/create',
			state: { record: { addonSetId: record.id, active: true } }
		}}
		label="Add Addon"
	>
		<AddonIcon />
	</Button>
));
