import React from 'react';
import { Edit, TextInput, BooleanInput, NumberInput, DisabledInput, FormTab } from 'react-admin';
import { TabbedForm } from '../customViews';
import AmounTypeTitle from './AmountTypeTitle';

const AmounTypeEdit = (props) => (
	<Edit title={<AmounTypeTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="name" />
				<TextInput source="currencyCode" />
				<TextInput source="ingenicoAppId" reference="ingenicoApps" />
				<NumberInput source="index" />
				<BooleanInput source="isDefault" />
				<BooleanInput source="isPaymentType" />
				<BooleanInput source="isCrossChecked" />
				<BooleanInput source="isCreditCard" />
				<BooleanInput source="isTicket" />
				<BooleanInput source="isCash" />
				<BooleanInput source="isOpeningAmount" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
				<DisabledInput source="createdBy" />
				<DisabledInput source="updatedBy" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default AmounTypeEdit;
