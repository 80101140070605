import React, { useEffect, useState } from 'react';
import {
	Edit,
	TextInput,
	BooleanInput,
	DisabledInput,
	FormTab,
	SelectInput,
	GET_LIST,
	number,
	minLength,
	maxLength
} from 'react-admin';
import { NameAutocompleteInput } from '../customInputs';
import EmployeeTitle from './EmployeeTitle';
import { TabbedForm } from '../customViews';
import dataProvider from '../dataProvider';

const EmployeeEdit = (props) => {
	const [employeeTypeList, setEmployeeTypeList] = useState([])
	useEffect(() => {
		const fetchData = () => {
			dataProvider(GET_LIST, 'employeeType', {
				filter: { active: true },
				sort: { field: 'name', order: 'DESC' },
				pagination: { page: 1, perPage: 50 }
			})
				.then((res) => {
					const employeeTypes = res.data.map((x) => ({ id: x.index, name: x.name }));
					setEmployeeTypeList(employeeTypes);
				})
				.catch((e) => {
					console.log('error', e)
				});
		};

		fetchData();
	}, []);


	return (
		<Edit title={<EmployeeTitle />} {...props}>
			<TabbedForm>
				<FormTab label="Details">
					<DisabledInput source="id" />
					<TextInput source="firstName" />
					<TextInput source="lastName" />
					<TextInput source="fullName" />
					<NameAutocompleteInput source="shopId" reference="shop">
					</NameAutocompleteInput>
					<BooleanInput source="checkedIn" />
					<BooleanInput source="isDefault" />
					<BooleanInput source="managesEmployees" />
					<BooleanInput source="managesProducts" />
					<TextInput source="pinCode" validate={[number(), minLength(4), maxLength(8)]} />
					<SelectInput source="type" choices={employeeTypeList} />
					<BooleanInput source="active" />
					<DisabledInput source="createdAt" />
					<DisabledInput source="updatedAt" />
					<DisabledInput source="createdBy" />
					<DisabledInput source="updatedBy" />
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};

export default EmployeeEdit;
