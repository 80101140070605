import React from 'react';
import { List, Datagrid, BooleanField, NumberField, BooleanInput, Filter, TextField, DateField, EditButton } from 'react-admin';

import { LookupFields } from '../customFields';
const ExchangeRateFilter = (props) => (
    <Filter {...props}>
        <BooleanInput source="active" alwaysOn />
    </Filter>
);

const ExchangeRateDataGrid = (props) => (
    <Datagrid rowClick="show" {...props}>
        <DateField source="currencyDate" />
        <TextField source="currencyCode" />
        <NumberField source="currencyRate" />
        <BooleanField source="active" key="active" />,
        <DateField source="createdAt" key="createdAt" />,
        <DateField source="updatedAt" key="updatedAt" />
        <EditButton />
    </Datagrid>
);

const ExchangeRateList = (props) => (
    <List
        filters={<ExchangeRateFilter />}
        filterDefaultValues={{ active: true }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        bulkActionButtons={false}
        {...props}
    >
        <ExchangeRateDataGrid />
    </List>
);

export default ExchangeRateList;
