import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	spacer: {
		flex: 1
	},
	appBar:{
		color:'#000',
		backgroundColor:'whitesmoke'
	}
};

const PayPadAppBar = withStyles(styles)(({ classes, ...props }) => (
	<AppBar {...props} className={classes.appBar}>
		<Typography variant="title" color="inherit" className={classes.title} id="react-admin-title" />
		<img style={{ margin: 6 }} src={'/images/HotelRunner_POS_logo.png'} width={50} height={'100%'} alt={'logo'} />
		<span className={classes.spacer} />
	</AppBar>
));

export default PayPadAppBar;
