import React from 'react';
import { List, Datagrid, EditButton, TextInput, BooleanInput, Filter } from 'react-admin';

import { LookupFields } from '../customFields';

const DeviceBrandFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

export const DeviceBrandDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		{LookupFields(props)}
		<EditButton />
	</Datagrid>
);

export const DeviceBrandList = (props) => (
	<List
		filters={<DeviceBrandFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<DeviceBrandDataGrid />
	</List>
);

export default DeviceBrandList;
