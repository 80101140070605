import React from 'react';
import { Datagrid, TextField, ImageField, EditButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ReferenceField, DefaultFields } from '../customFields';

const styles = {
	image: { maxHeight: '4em', width: '4em', padding: 0, margin: 0 }
};

const CompanyDataGrid = withStyles(styles)(({ classes, ...props }) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		<ImageField source="logo" title="Logo" classes={classes} />
		<ReferenceField source="userId" reference="account" linkType="show">
			<TextField source="fullName" />
		</ReferenceField>
		{DefaultFields(props)} <EditButton />
	</Datagrid>
));

export default CompanyDataGrid;
