import React from 'react';
import { List, Datagrid, BooleanInput, BooleanField, Filter, TextInput } from 'react-admin';

import { ColorField } from 'react-admin-color-input';

import { LookupFields, CompanyReferenceField } from '../customFields';
import { NameAutocompleteInput } from '../customInputs';

const AddonSetFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<NameAutocompleteInput source="companyId" reference="company" />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const AddonSetDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		{LookupFields(props)}
		<ColorField source="colorHex" />
		<BooleanField source="isMultiSelect" />
		{CompanyReferenceField(props)}
	</Datagrid>
);

const AddonSetList = (props) => (
	<List
		filters={<AddonSetFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<AddonSetDataGrid />
	</List>
);

export default AddonSetList;
