import AmountTypeIcon from '@material-ui/icons/AttachMoney';
import AmountTypeList from './AmountTypeList';
import AmountTypeShow from './AmountTypeShow';
import AmountTypeEdit from './AmountTypeEdit';
import AmountTypeCreate from './AmountTypeCreate';

export default {
	list: AmountTypeList,
	show: AmountTypeShow,
	edit: AmountTypeEdit,
	create: AmountTypeCreate,
	icon: AmountTypeIcon,
	name: 'amountType'
};
