import React from 'react';
import { Edit, BooleanInput, DisabledInput, FormTab } from 'react-admin';
import { TabbedForm } from '../customViews';
import OpenOrderTitle from './OpenOrderTitle';

const redirect = (basePath, id, data) => `/shift/${data.shiftId}/show/openOrders`;

const OpenOrderEdit = (props) => (
	<Edit title={<OpenOrderTitle />} {...props}>
		<TabbedForm redirect={redirect}>
			<FormTab label="Details">
				<BooleanInput source="active" />
				<DisabledInput source="totalAmount" />
				<DisabledInput source="seqId" />
				<DisabledInput source="fNo" />
				<DisabledInput source="zNo" />
				<DisabledInput source="note" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default OpenOrderEdit;
