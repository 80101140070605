import React from 'react';
import { Datagrid, TextField, FunctionField, EditButton } from 'react-admin';

import { ReferenceField, DefaultFields } from '../customFields';
import TaskTypeField from './TaskTypeField';
import TaskStateField from './TaskStateField';

const getDetail = (record) => {
	if (record && record.taskType && record.parameters) {
		return `${record.taskType === 0 ? record.parameters.version : record.parameters.message}`;
	}
	return '';
};

const DeviceTaskDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TaskTypeField source="taskType" />
		<TaskStateField source="taskState" />
		<TextField source="priority" />
		<ReferenceField source="registerId" reference="register">
			<TextField source="id" />
		</ReferenceField>
		<FunctionField label="Detail" render={getDetail} />
		{DefaultFields(props)} <EditButton />
	</Datagrid>
);

export default DeviceTaskDataGrid;
