import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import SendIcon from '@material-ui/icons/Send';
import { fetchEnd, fetchStart, showNotification, Button } from 'react-admin';

import { SHIFT_SEND_REPORT } from '../constants';
import dataProvider from '../dataProvider';

const ShiftSendReportButton = (
	props,
	{
		basePath = '',
		label = 'action.sendShiftEmail',
		record = {},
		icon = <SendIcon />,
		handleClick = (e, id) => {
			const { fetchStart, fetchEnd, showNotification } = props;

			e.stopPropagation();
			console.log(id);
			// Dispatch an action letting react-admin know a API call is ongoing
			fetchStart();

			// As we want to know when the action has been completed, we use the
			// dataProvider directly
			dataProvider(SHIFT_SEND_REPORT, `ReportMailer/SendShiftReport?shiftId=${id}`, {})
				.then(({ data }) => {
					showNotification('message.actionSuccess', 'success');
				})
				.catch((error) => {
					showNotification(error.message, 'error');
				})
				.finally(() => {
					// Dispatch an action letting react-admin know a API call has ended
					fetchEnd();
				});
		},
		...rest
	}
) => (
	<Button label={label} onClick={(e) => handleClick(e, props.record.id)} {...rest}>
		{icon}
	</Button>
);

ShiftSendReportButton.propTypes = {
	basePath: PropTypes.string,
	className: PropTypes.string,
	classes: PropTypes.object,
	label: PropTypes.string,
	record: PropTypes.object,
	icon: PropTypes.element
};

const enhance = shouldUpdate(
	(props, nextProps) =>
		props.translate !== nextProps.translate ||
		(props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
		props.basePath !== nextProps.basePath ||
		(props.record == null && nextProps.record != null)
);

export default connect(undefined, { showNotification, fetchStart, fetchEnd })(enhance(ShiftSendReportButton));
