import QuickKeyFunctionIcon from '@material-ui/icons/Input';
import QuickKeyFunctionList from './QuickKeyFunctionList';
import QuickKeyFunctionShow from './QuickKeyFunctionShow';
import QuickKeyFunctionEdit from './QuickKeyFunctionEdit';
import QuickKeyFunctionCreate from './QuickKeyFunctionCreate';

export default {
	list: QuickKeyFunctionList,
	show: QuickKeyFunctionShow,
	edit: QuickKeyFunctionEdit,
	create: QuickKeyFunctionCreate,
	icon: QuickKeyFunctionIcon,
	name:'quickKeyFunction'
};
