import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	BooleanField,
	EditButton,
	NumberField,
	TextInput,
	BooleanInput,
	Filter
} from 'react-admin';

import { DefaultFields } from '../customFields';

const AmounTypeFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const AmounTypeDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		<TextField source="currencyCode" />
		<NumberField source="index" />
		<BooleanField source="isDefault" />
		<BooleanField source="isPaymentType" />
		<BooleanField source="isCrossChecked" />
		<BooleanField source="isCreditCard" />
		<BooleanField source="isTicket" />
		<BooleanField source="isCash" />
		<BooleanField source="isOpeningAmount" />
		<TextField source="ingenicoAppId" reference="ingenicoApps" />
		<DefaultFields />Î
		<EditButton />
	</Datagrid>
);

const AmounTypeList = (props) => (
	<List
		filters={<AmounTypeFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<AmounTypeDataGrid />
	</List>
);

export default AmounTypeList;
