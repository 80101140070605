import React from 'react';
import { Show, TextField, BooleanField,  NumberField, TabbedShowLayout, Tab } from 'react-admin';

import { ReferenceField,DateField } from '../customFields';
import AddonTitle from './AddonTitle';

const AddonShow = (props) => (
	<Show title={<AddonTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<NumberField source="index" />
				<ReferenceField source="addonSetId" reference="addonSet">
					<TextField source="name" />
				</ReferenceField>
				<NumberField source="amount" />
				<BooleanField source="active" />
				<DateField source="createdAt"  />
				<DateField source="updatedAt"  />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default AddonShow;
