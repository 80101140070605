import React from 'react';
import { Edit, TextInput, BooleanInput, FormTab, number } from 'react-admin';

import { TabbedForm } from '../customViews';
import { NameAutocompleteInput } from '../customInputs';
import CompanyAmountTypeTitle from './CompanyAmountTypeTitle';

const CompanyAmounTypeEdit = (props) => (
	<Edit title={<CompanyAmountTypeTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="id" />
				<TextInput source="index" validate={number()}/>
				<NameAutocompleteInput source="companyId" reference="company" />
				<NameAutocompleteInput source="amountTypeId" reference="amountType" />
				<TextInput source="name" />
				<BooleanInput source="isCrossChecked" />
				<BooleanInput source="opensCashDrawer" />
				<BooleanInput source="isVisible" />
				<BooleanInput source="active" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default CompanyAmounTypeEdit;
