import React from 'react';
import { Datagrid, TextField, BooleanField, EditButton, NumberField } from 'react-admin';

import { ShopReferenceField, DefaultFields } from '../customFields';

const RegisterDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="id" />
		<TextField source="deviceId" />
		{/* <TextField source="pushToken" /> */}
		<BooleanField source="isOpen" />
		<BooleanField source="isSimulator" />
		<NumberField source="registerType" />
		<TextField source="paypadClientVersion" />
		<TextField source="name" />
		<TextField source="deviceName" />
		<TextField source="osName" />
		<TextField source="osVersion" />
		{ShopReferenceField(props)}
		{DefaultFields(props)} <EditButton />
	</Datagrid>
);

export default RegisterDataGrid;
