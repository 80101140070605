import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab, ReferenceManyField } from 'react-admin';
import { ColorField } from 'react-admin-color-input';

import { DateField } from '../customFields';
import AddonSetTitle from './AddonSetTitle';
import ProductAddonSetDataGrid from '../productAddonSets/ProductAddonSetDataGrid';
import AddonDataGrid from '../addons/AddonDataGrid';
import { AddNewAddonButton, AddNewProductButton } from './AddButtons';

const AddonSetShow = (props) => (
	<Show title={<AddonSetTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<NumberField source="index" />
				<ColorField source="colorHex" />
				<BooleanField source="isMultiSelect" />
				<BooleanField source="active" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
			</Tab>
			<Tab label="Addons" path="addons">
				<ReferenceManyField reference="addon" target="addonSetId" addLabel={false} perPage={1000}>
					<AddonDataGrid />
				</ReferenceManyField>
				<AddNewAddonButton />
			</Tab>
			<Tab label="Products" path="products">
				<ReferenceManyField reference="productAddonSet" target="addonSetId" addLabel={false} perPage={1000}>
					<ProductAddonSetDataGrid />
				</ReferenceManyField>
				<AddNewProductButton />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default AddonSetShow;
