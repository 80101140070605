import React from 'react';
import { BooleanField } from 'react-admin';
import DateField from './DateField';

const DefaultFields = (props) => {
	return [
		<BooleanField source="active" key="active" />,
		<DateField source="createdAt" key="createdAt" />,
		<DateField source="updatedAt" key="updatedAt" />
	];
};

export default DefaultFields;
