import React from 'react';
import { Datagrid, TextField, NumberField } from 'react-admin';

import { ReferenceField, DefaultFields } from '../customFields';

const AddonDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<TextField source="name" />
		<NumberField source="index" />
		<ReferenceField source="addonSetId" reference="addonSet">
			<TextField source="name" />
		</ReferenceField>
		<NumberField source="amount" />
		{DefaultFields(props)}{' '}
	</Datagrid>
);

export default AddonDataGrid;
