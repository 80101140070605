import React from 'react';
import { Datagrid, TextField, EditButton, ReferenceField } from 'react-admin';

import { ShopReferenceField } from '../customFields';

const RoleMappingDataGrid = (props) => (
	<Datagrid {...props}>
		{/* <TextField source="id" /> */}
		{/* <TextField source="principalType" /> */}
		{window.location.href.includes('show') ? null : (
			<ReferenceField source="principalId" reference="account" linkType="show">
				<TextField source="fullName" />
			</ReferenceField>
		)}
		<ReferenceField source="roleId" reference="roles" linkType={false}>
			<TextField source="name" />
		</ReferenceField>
		{ShopReferenceField(props)}
		<ReferenceField source="companyId" reference="company" allowEmpty linkType="show">
			<TextField source="name" />
		</ReferenceField>
		<EditButton />
	</Datagrid>
);

export default RoleMappingDataGrid;
