import React from 'react';
import { List, TextInput, BooleanInput, Filter } from 'react-admin';

import { NameAutocompleteInput } from '../customInputs';
import ProductDataGrid from './ProductDataGrid';

const ProductFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<NameAutocompleteInput source="companyId" reference="company" />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const ProductList = (props) => (
	<List
		filters={<ProductFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'updatedAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<ProductDataGrid />
	</List>
);

export default ProductList;
