import React from 'react';
import { Show, TextField, BooleanField, TabbedShowLayout, Tab } from 'react-admin';

import DeviceModelTitle from './DeviceModelTitle';
import { ReferenceField, DateField } from '../customFields';

export const DeviceModelShow = (props) => (
	<Show title={<DeviceModelTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
			<TextField source="index" />
				<TextField source="name" />
				<ReferenceField source="deviceTypeId" reference="deviceType" linkType="show">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField source="deviceBrandId" reference="deviceBrand" linkType="show">
					<TextField source="name" />
				</ReferenceField>
				<BooleanField source="active" />
				<DateField source="createdAt"  />
				<DateField source="updatedAt"  />
				<TextField source="createdBy" />
				<TextField source="updatedBy" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default DeviceModelShow;
