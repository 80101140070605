import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const getFilterQuery = (searchField, searchText) => {
	const filter = {};
	filter[searchField] = searchText;
	filter.useLike = [searchField];
	return filter;
};

const ReferenceAutocompleteInput = ({ searchField, optionText, ...rest }) => {
	return (
		<ReferenceInput
			sort={{ field: searchField, order: 'ASC' }}
			filterToQuery={(searchText) => getFilterQuery(searchField, searchText)}
			allowEmpty
			{...rest}
		>
			<AutocompleteInput optionText={optionText || searchField} />
		</ReferenceInput>
	);
};

export default ReferenceAutocompleteInput;
