import EmployeeIcon from '@material-ui/icons/AssignmentInd';
import EmployeeList from './EmployeeList';
import EmployeeShow from './EmployeeShow';
import EmployeeEdit from './EmployeeEdit';
import EmployeeCreate from './EmployeeCreate';

export default {
	list: EmployeeList,
	show: EmployeeShow,
	edit: EmployeeEdit,
	create: EmployeeCreate,
	icon: EmployeeIcon,
	name: 'employee'
};
