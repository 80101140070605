import React from 'react';
import { Edit, TextField, BooleanInput, NumberInput, DisabledInput, FormTab } from 'react-admin';

import { ColorInput } from 'react-admin-color-input';

import { TabbedForm } from '../customViews';
import AddonSetTitle from './AddonSetTitle';

const AddonSetEdit = (props) => (
	<Edit title={<AddonSetTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<DisabledInput source="id" />
				<TextField source="name" />
				<NumberInput source="index" />
				<ColorInput source="colorHex" />
				<BooleanInput source="isMultiSelect" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default AddonSetEdit;
