import OutputCenterIcon from '@material-ui/icons/NotificationsActive';
import OutputCenterList from './OutputCenterList';
import OutputCenterShow from './OutputCenterShow';
import OutputCenterEdit from './OutputCenterEdit';
import OutputCenterCreate from './OutputCenterCreate';

export default {
	list: OutputCenterList,
	show: OutputCenterShow,
	edit: OutputCenterEdit,
	create: OutputCenterCreate,
	icon: OutputCenterIcon,
	name:'outputCenter'
};
