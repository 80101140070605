import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab } from 'react-admin';

import { ReferenceField, DateField } from '../customFields';
import ProductAddonSetTitle from './ProductAddonSetTitle';

const ProductAddonSetShow = (props) => (
	<Show title={<ProductAddonSetTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<NumberField source="index" />
				<ReferenceField source="productId" reference="product">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField source="addonSetId" reference="addonSet">
					<TextField source="name" />
				</ReferenceField>
				<BooleanField source="active" />
				<DateField source="createdAt"  />
				<DateField source="updatedAt"  />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ProductAddonSetShow;
