import ShiftIcon from '@material-ui/icons/Store';
import ShiftList from './ShiftList';
import ShiftShow from './ShiftShow';
import ShiftEdit from './ShiftEdit';
//import ListGuesser from 'react-admin'

export default {
	list: ShiftList,
	show: ShiftShow,
	edit: ShiftEdit,
	icon: ShiftIcon,
	name: 'shift'
};
