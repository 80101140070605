import React from 'react';
import { Edit, TextInput, BooleanInput, DisabledInput, FormTab, number } from 'react-admin';
import { TabbedForm } from '../customViews';
import DeviceModelTitle from './DeviceModelTitle';
import { NameAutocompleteInput } from '../customInputs';

const DeviceModelEdit = (props) => (
	<Edit title={<DeviceModelTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="index" validate={number()} />
				<TextInput source="name" />
				<NameAutocompleteInput source="deviceBrandId" reference="deviceBrand" />
                <NameAutocompleteInput source="deviceTypeId" reference="deviceType" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
				<DisabledInput source="createdBy" />
				<DisabledInput source="updatedBy" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default DeviceModelEdit;
