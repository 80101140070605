import React from 'react';
import { Edit, BooleanInput, FormTab } from 'react-admin';

import { TabbedForm } from '../customViews';
import { NameAutocompleteInput } from '../customInputs';
import ProductAddonSetTitle from './ProductAddonSetTitle';

const ProductAddonSetEdit = (props) => (
	<Edit title={<ProductAddonSetTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<NameAutocompleteInput source="productId" reference="product" />
				<NameAutocompleteInput source="addonSetId" reference="addonSet" />
				<BooleanInput source="active" defaultValue={true} />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default ProductAddonSetEdit;
