import React from 'react';
import { Edit, TextInput, BooleanInput, FormTab } from 'react-admin';

import { JsonInput, NameAutocompleteInput } from '../customInputs';
import { TabbedForm } from '../customViews';
import DeviceTitle from './DeviceTitle';

const DeviceEdit = (props) => (
	<Edit title={<DeviceTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<NameAutocompleteInput source="shopId" reference="shop" />
				<NameAutocompleteInput source="deviceModelId" reference="deviceModel" />
				<TextInput source="serialNumber" />
				<TextInput source="name" />
				<TextInput source="osName" />
				<TextInput source="osVersion" />
				<TextInput source="appVersion" />
				<TextInput source="ipAddress" />
				<TextInput source="macAddress" />
				<BooleanInput source="active" />
			</FormTab>
			<FormTab label="Settings" path="settings">
				<JsonInput source="properties" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default DeviceEdit;
