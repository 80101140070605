import React from 'react';
import { Edit, ImageField, TextInput, BooleanInput, DisabledInput, ImageInput, FormTab } from 'react-admin';

import { JsonInput } from '../customInputs';
import { TabbedForm } from '../customViews';
import CompanyTitle from './CompanyTitle';

const CompanyEdit = (props) => (
	<Edit title={<CompanyTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<DisabledInput source="id" />
				<TextInput source="name" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
			</FormTab>
			<FormTab label="Settings" path="settings">
				<ImageField source="logo" label="Logo Preview" />
				<ImageInput source="logo">
					<ImageField source="title" />
				</ImageInput>
				<JsonInput source="settings" />
				<JsonInput source="integrationSettings" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default CompanyEdit;
