import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import DeviceIcon from '@material-ui/icons/Devices';
import EmployeeIcon from '@material-ui/icons/Person';
import OutputCenterIcon from '@material-ui/icons/NotificationsActive';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	button: {
		marginTop: '1em'
	}
};

export const AddNewDeviceButton = withStyles(styles)(({ classes, record }) => (
	<Button
		className={classes.button}
		variant="raised"
		component={Link}
		to={{
			pathname: '/device/create',
			state: { record: { shopId: record.id, active: true } }
		}}
		label="Add Device"
	>
		<DeviceIcon />
	</Button>
));

export const AddNewEmployeeButton = withStyles(styles)(({ classes, record }) => (
	<Button
		className={classes.button}
		variant="raised"
		component={Link}
		to={{
			pathname: '/employee/create',
			state: { record: { shopId: record.id, active: true } }
		}}
		label="Add Employee"
	>
		<EmployeeIcon />
	</Button>
));

export const AddNewOutputCenterButton = withStyles(styles)(({ classes, record }) => (
	<Button
		className={classes.button}
		variant="raised"
		component={Link}
		to={{
			pathname: '/outputCenter/create',
			state: { record: { shopId: record.id, active: true } }
		}}
		label="Add Output Center"
	>
		<OutputCenterIcon />
	</Button>
));
