import React from 'react';
import { Datagrid, TextField, BooleanField, EditButton, NumberField } from 'react-admin';

import { DefaultFields, ShopReferenceField } from '../customFields';
const EmployeeDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}> 
		<TextField source="fullName" />
		<BooleanField source="checkedIn" />
		<BooleanField source="isDefault" />
		<BooleanField source="managesEmployees" />
		<BooleanField source="managesProducts" />
		<NumberField source="pinCode" />
		<NumberField source="type" />
		{ShopReferenceField(props)}
		{DefaultFields(props)}
		<EditButton />
	</Datagrid>
);

export default EmployeeDataGrid;
