import OpenOrderIcon from '@material-ui/icons/DeviceHub';
//import OpenOrderList from './OpenOrderList';
import OpenOrderShow from './OpenOrderShow';
import OpenOrderEdit from './OpenOrderEdit';

export default {
	//list: OpenOrderList,
	show: OpenOrderShow,
	edit: OpenOrderEdit,
	icon: OpenOrderIcon,
	name: 'openOrder'
};
