import ExchangeRateIcon from '@material-ui/icons/MoneyOff';
import ExchangeRateList from './ExchangeRateList';
import ExchangeRateShow from './ExchangeRateShow';
import ExchangeRateCreate from './ExchangeRateCreate';
import ExchangeRateEdit from './ExchangeRateEdit';
 

export default {
	list: ExchangeRateList,
	show: ExchangeRateShow,
	create: ExchangeRateCreate,
	edit: ExchangeRateEdit,
	icon: ExchangeRateIcon,
	name: 'exchangeRate'
};
