import RegisterIcon from '@material-ui/icons/TabletMac';
import RegisterList from './RegisterList';
import RegisterShow from './RegisterShow';
import RegisterEdit from './RegisterEdit';
//import RegisterCreate from './RegisterCreate';

export default {
	list: RegisterList,
	show: RegisterShow,
	edit: RegisterEdit,
	//create: RegisterCreate,
	icon: RegisterIcon,
	name: 'register'
};
