import React from 'react';
import { Show, TextField, TabbedShowLayout, Tab } from 'react-admin';

import { JsonField, ReferenceField, DefaultFields } from '../customFields';

import DeviceTaskTitle from './DeviceTaskTitle';
import TaskTypeField from './TaskTypeField';
import TaskStateField from './TaskStateField';

const DeviceTaskShow = (props) => (
	<Show title={<DeviceTaskTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TaskTypeField source="taskType" />
				<TaskStateField source="taskState" />
				<TextField source="priority" />
				<ReferenceField source="registerId" reference="register">
					<TextField source="id" />
				</ReferenceField>
				{DefaultFields(props)}
			</Tab>
			<Tab label="Parameters" path="parameters">
				<JsonField source="parameters" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default DeviceTaskShow;
