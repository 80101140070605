import RevenueCenterIcon from '@material-ui/icons/EventSeat';
import RevenueCenterList from './RevenueCenterList';
import RevenueCenterShow from './RevenueCenterShow';
import RevenueCenterEdit from './RevenueCenterEdit';
import RevenueCenterCreate from './RevenueCenterCreate';

export default {
	list: RevenueCenterList,
	show: RevenueCenterShow,
	create: RevenueCenterCreate,
	edit: RevenueCenterEdit,
	icon: RevenueCenterIcon,
	name: 'revenueCenter'
};
