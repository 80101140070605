import React from 'react';
import { Edit, TextInput, BooleanInput, NumberInput, DisabledInput, FormTab, TextField } from 'react-admin';

import { TabbedForm } from '../customViews';
import { ReferenceField } from '../customFields';
import OutputCenterTitle from './OutputCenterTitle';

const OutputCenterEdit = (props) => (
	<Edit title={<OutputCenterTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<ReferenceField source="shopId" reference="shop">
					<TextField source="name" />
				</ReferenceField>
				<TextInput source="name" />
				<NumberInput source="index" />
				<BooleanInput source="isCheckout" />
				<BooleanInput source="isGo" />

				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
				<DisabledInput source="createdBy" />
				<DisabledInput source="updatedBy" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default OutputCenterEdit;
