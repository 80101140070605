import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';

import AccountTitle from './AccountTitle';

const AccountCreate = (props) => (
	<Create title={<AccountTitle />} {...props}>
		<SimpleForm>
			<TextInput source="firstName" />
			<TextInput source="lastName" />
			<TextInput source="fullName" />
			<TextInput source="username" />
			<TextInput source="password" />
			<BooleanInput source="active" />
		</SimpleForm>
	</Create>
);

export default AccountCreate;
