import React, { Fragment } from 'react';
import {
	Edit,
	BooleanInput,
	ReferenceInput,
	NumberInput,
	AutocompleteInput,
	FormTab,
	FormDataConsumer
} from 'react-admin';

import { JsonInput } from '../customInputs';
import { TabbedForm } from '../customViews';
import DeviceTaskTitle from './DeviceTaskTitle';
import TaskTypeInput from './TaskTypeInput';
import TaskStateInput from './TaskStateInput';
import { NameAutocompleteInput } from '../customInputs';

const DeviceTaskEdit = (props) => (
	<Edit title={<DeviceTaskTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<FormDataConsumer>
					{({ formData, dispatch, ...rest }) => (
						<Fragment>
							<NameAutocompleteInput perPage={100} source="shopId" reference="shop" {...rest} />
							<ReferenceInput
								perPage={100}
								source="registerId"
								reference="register"
								allowEmpty
								filterToQuery={(searchText) => ({
									active: true,
									isOpen: true,
									name: searchText,
									shopId: formData.shopId,
									useLike: [ 'name' ]
								})}
								filter={{ shopId: formData.shopId, active: true, isOpen: true }}
								{...rest}
							>
								<AutocompleteInput optionText="name" />
							</ReferenceInput>
						</Fragment>
					)}
				</FormDataConsumer>
				<TaskTypeInput source="taskType" />
				<TaskStateInput source="taskState" />
				<NumberInput source="priority" />
				<BooleanInput source="active" defaultValue={true} />
			</FormTab>
			<FormTab label="Parameters" path="parameters">
				<JsonInput source="parameters" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default DeviceTaskEdit;
