import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab } from 'react-admin';

import { DateField } from '../customFields';
import UnitTypeTitle from './UnitTypeTitle';

const UnitTypeShow = (props) => (
	<Show title={<UnitTypeTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<NumberField source="index" />
				{/* <ReferenceField source="quantityTypeId" reference="quantityType">
					<TextField source="name" />
				</ReferenceField> */}
				<NumberField source="minQuantity" />
				<NumberField source="maxQuantity" />
				<BooleanField source="isQuantityRequired" />
				<BooleanField source="active" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default UnitTypeShow;
