import React from 'react'
import {
    Create,
    TextInput,
    BooleanInput,
    SimpleForm,
    NumberInput
}
    from 'react-admin'

import UnitTypeTitle from './UnitTypeTitle';

export default function UnitTypeCreate(props) {
    return (
        <Create title={<UnitTypeTitle />} {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <NumberInput source="index" />
                <NumberInput source="minQuantity" />
                <NumberInput source="maxQuantity" />
                <BooleanInput source="isQuantityRequired" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
    )
}
