import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import RoleMappingIcon from '@material-ui/icons/AccountBox';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	button: {
		marginTop: '1em'
	}
};

export const AddNewRoleMappingButton = withStyles(styles)(({ classes, record }) => (
	<Button
		className={classes.button}
		variant="raised"
		component={Link}
		to={{
			pathname: '/roleMappings/create',
			state: { record: { principalId: record.id, principalType: 'USER', fullName: record.fullName } }
		}}
		label="Add Role Mapping"
	>
		<RoleMappingIcon />
	</Button>
));
