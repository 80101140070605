import DeviceIcon from '@material-ui/icons/Devices';
import DeviceList from './DeviceList';
import DeviceShow from './DeviceShow';
import DeviceEdit from './DeviceEdit';
import DeviceCreate from './DeviceCreate';

export default {
	list: DeviceList,
	show: DeviceShow,
	edit: DeviceEdit,
	create: DeviceCreate,
	icon: DeviceIcon,
	name: 'device'
};
