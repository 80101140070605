import React, { Component } from 'react';
import { GET_LIST, Responsive, withDataProvider } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import People from '@material-ui/icons/People';
import AccountBalance from '@material-ui/icons/AccountBalance';
import ShopIcon from '@material-ui/icons/Store';
import Devices from '@material-ui/icons/Devices';


import CustomLatestList from './CustomLatestList';

const styles = {
	flex: { display: 'flex', gap: 20, paddingTop: 32 },
	flexColumn: { display: 'flex', flexDirection: 'column', padding: '32px 20px', gap: 75 },
	leftCol: { flex: 1, display: 'flex', flexDirection: 'column', gap: 75 },
	rightCol: { flex: 1, display: 'flex', flexDirection: 'column', gap: 75 },
	singleCol: { marginTop: '2em', marginBottom: '2em' }
};

class Dashboard extends Component {
	state = {
		allAccounts: [],
		allCompany: [],
		allShop: [],
		allDevice: []
	};
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.version !== prevProps.version) {
			this.fetchData();
		}
	}

	fetchData() {
		this.fetchAllAccounts();
		this.fetchAllCompany();
		this.fetchAllShop();
		this.fetchAllDevice();
	}

	async fetchAllAccounts() {
		const { dataProvider } = this.props;
		const { data: allAccounts } = await dataProvider(GET_LIST, 'account', {
			filter: {
				active: true
			},
			sort: { field: 'createdAt', order: 'DESC' },
			pagination: { page: null, perPage: null }
		});
		this.setState({
			allAccounts,
		});
	}

	async fetchAllCompany() {
		const { dataProvider } = this.props;
		const { data: allCompany } = await dataProvider(GET_LIST, 'company', {
			filter: {
				active: true
			},
			sort: { field: 'createdAt', order: 'DESC' },
			pagination: { page: null, perPage: null }
		});
		this.setState({
			allCompany,
		});
	}

	async fetchAllShop() {
		const { dataProvider } = this.props;
		const { data: allShop } = await dataProvider(GET_LIST, 'shop', {
			filter: {
				active: true
			},
			sort: { field: 'createdAt', order: 'DESC' },
			pagination: { page: null, perPage: null }
		});
		this.setState({
			allShop,
		});
	}

	async fetchAllDevice() {
		const { dataProvider } = this.props;
		const { data: allDevice } = await dataProvider(GET_LIST, 'device', {
			filter: {
				active: true
			},
			sort: { field: 'createdAt', order: 'DESC' },
			pagination: { page: null, perPage: null }
		});
		this.setState({
			allDevice,
		});
	}

	render() {
		const {
			allAccounts,
			allCompany,
			allShop,
			allDevice
		} = this.state;
		return (
			<Responsive
				xsmall={
					<div>
						<div style={styles.flexColumn}>
							<CustomLatestList
								value={allCompany}
								totalTitle={'Company'}
								titleFromTranslate={'pos.dashboard.latestFiveCompany'}
								dataCount={5}
								linkSource={'company'}
								icon={AccountBalance} />
							<CustomLatestList
								value={allShop}
								totalTitle={'Shop'}
								titleFromTranslate={'pos.dashboard.latestFiveShop'}
								dataCount={5}
								linkSource={'shop'} 
								icon={ShopIcon}/>
							<CustomLatestList
								value={allAccounts}
								totalTitle={'Account'}
								titleFromTranslate={'pos.dashboard.latestFiveAccount'}
								dataCount={5}
								linkSource={'account'}
								icon={People} />
							<CustomLatestList
								value={allDevice}
								totalTitle={'Device'}
								titleFromTranslate={'pos.dashboard.latestFiveDevice'}
								dataCount={5}
								linkSource={'device'}
								icon={Devices}/>
						</div>
					</div>
				}
				small={
					<div style={styles.flexColumn}>
						<CustomLatestList
							value={allCompany}
							totalTitle={'Company'}
							titleFromTranslate={'pos.dashboard.latestFiveCompany'}
							dataCount={5}
							linkSource={'company'}
							icon={AccountBalance} />
						<CustomLatestList
							value={allShop}
							totalTitle={'Shop'}
							titleFromTranslate={'pos.dashboard.latestFiveShop'}
							dataCount={5}
							linkSource={'shop'}
							icon={ShopIcon} />
						<CustomLatestList
							value={allAccounts}
							totalTitle={'Account'}
							titleFromTranslate={'pos.dashboard.latestFiveAccount'}
							dataCount={5}
							linkSource={'account'} 
							icon={People}/>
						<CustomLatestList
							value={allDevice}
							totalTitle={'Device'}
							titleFromTranslate={'pos.dashboard.latestFiveDevice'}
							dataCount={5}
							linkSource={'device'} 
							icon={Devices}/>
					</div>
				}
				medium={
					<div style={styles.flex}>
						<div style={styles.leftCol}>
							<CustomLatestList
								value={allCompany}
								totalTitle={'Company'}
								titleFromTranslate={'pos.dashboard.latestFiveCompany'}
								dataCount={5}
								linkSource={'company'}
								icon={AccountBalance} />
							<CustomLatestList
								value={allShop}
								totalTitle={'Shop'}
								titleFromTranslate={'pos.dashboard.latestFiveShop'}
								dataCount={5}
								linkSource={'shop'}
								icon={ShopIcon} />
						</div>
						<div style={styles.rightCol}>
							<CustomLatestList
								value={allAccounts}
								totalTitle={'Account'}
								titleFromTranslate={'pos.dashboard.latestFiveAccount'}
								dataCount={5}
								linkSource={'account'}
								icon={People}/>
							<CustomLatestList
								value={allDevice}
								totalTitle={'Device'}
								titleFromTranslate={'pos.dashboard.latestFiveDevice'}
								dataCount={5}
								linkSource={'device'}
								icon={Devices} />
						</div>

					</div>
				}
				large={
					<div style={styles.flex}>
						<CustomLatestList
							value={allCompany}
							totalTitle={'Company'}
							titleFromTranslate={'pos.dashboard.latestFiveCompany'}
							dataCount={5}
							linkSource={'company'}
							icon={AccountBalance} />
						<CustomLatestList
							value={allShop}
							totalTitle={'Shop'}
							titleFromTranslate={'pos.dashboard.latestFiveShop'}
							dataCount={5}
							linkSource={'shop'}
							icon={ShopIcon} />
						<CustomLatestList
							value={allAccounts}
							totalTitle={'Account'}
							titleFromTranslate={'pos.dashboard.latestFiveAccount'}
							dataCount={5}
							linkSource={'account'}
							icon={People} />
						<CustomLatestList
							value={allDevice}
							totalTitle={'Device'}
							titleFromTranslate={'pos.dashboard.latestFiveDevice'}
							dataCount={5}
							linkSource={'device'}
							icon={Devices} />
					</div>
				}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	version: state.admin.ui.viewVersion,
});

export default compose(connect(mapStateToProps), withDataProvider)(Dashboard);
