import React from 'react';
import { Edit, TextInput, BooleanInput, DisabledInput, FormTab } from 'react-admin';
import { TabbedForm } from '../customViews';
import DeviceTypeTitle from './DeviceTypeTitle';

const DeviceTypeEdit = (props) => (
	<Edit title={<DeviceTypeTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="index" />
				<TextInput source="name" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
				<DisabledInput source="createdBy" />
				<DisabledInput source="updatedBy" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default DeviceTypeEdit;
