import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, NumberInput, DisabledInput, LongTextInput } from 'react-admin';

import AccountTitle from './AccountTitle';

const AccountEdit = (props) => (
	<Edit title={<AccountTitle />} {...props}>
		<SimpleForm>
			<DisabledInput source="id" />
			<NumberInput source="type" />
			<TextInput source="username" />
			<TextInput source="email" />
			<TextInput source="firstName" />
			<TextInput source="lastName" />
			<TextInput source="fullName" />
			<TextInput source="phoneNumber" />
			<LongTextInput source="settings" />
			<BooleanInput source="active" />
			<DisabledInput source="createdAt" />
			<DisabledInput source="updatedAt" />
		</SimpleForm>
	</Edit>
);

export default AccountEdit;
