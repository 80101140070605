import React from 'react';
import { Edit, ImageField, TextInput, BooleanInput, DisabledInput, FormTab, ImageInput } from 'react-admin';

import { JsonInput } from '../customInputs';
import { TabbedForm } from '../customViews';
import ShopTitle from './ShopTitle';

export const ShopEdit = (props) => (
	<Edit title={<ShopTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<DisabledInput source="id" />
				<TextInput source="name" />
				<TextInput source="city" />
				<TextInput source="postcode" />
				<TextInput source="address" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
			</FormTab>
			<FormTab label="Settings" path="settings">
				<ImageField source="logo" label="Logo Preview" />
				<ImageInput source="logo">
					<ImageField source="src" />
				</ImageInput>
				<JsonInput source="settings" />
				<JsonInput source="serverSettings	" />
				<JsonInput source="integrationSettings" />
			</FormTab>
		</TabbedForm>
	</Edit>
);
export default ShopEdit;
