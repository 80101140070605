import DeviceBrandIcon from '@material-ui/icons/LocalParking';
import DeviceBrandList from './DeviceBrandList';
import DeviceBrandShow from './DeviceBrandShow';
import DeviceBrandEdit from './DeviceBrandEdit';
import DeviceBrandCreate from './DeviceBrandCreate';
//import DeviceBrandCreate from './DeviceBrandCreate';

export default {
	list: DeviceBrandList,
	show: DeviceBrandShow,
	edit: DeviceBrandEdit,
	create: DeviceBrandCreate,
	icon: DeviceBrandIcon,
	name: 'deviceBrand'
};
