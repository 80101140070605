import React from 'react';
import { Edit, TextInput, BooleanInput, DisabledInput, FormTab, number } from 'react-admin';

import { TabbedForm } from '../customViews';
import ShopRevenueCenterTitle from './ShopRevenueCenterTitle';
import { NameAutocompleteInput } from '../customInputs';

const ShopRevenueCenterEdit = (props) => (
    <Edit title={<ShopRevenueCenterTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Details">
                <TextInput source="index" validate={number()} />
                <TextInput source="name" />
                <NameAutocompleteInput source='shopId' reference="shop" />
                <NameAutocompleteInput source='revenueCenterId' reference="revenueCenter" />
                <DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
                <BooleanInput source="active" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ShopRevenueCenterEdit;
