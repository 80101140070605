import React from 'react';
import { Show, TextField, NumberField, BooleanField, TabbedShowLayout, Tab } from 'react-admin';

import { DateField } from '../customFields';
import OpenOrderTitle from './OpenOrderTitle';

const OpenOrderShow = (props) => (
	<Show title={<OpenOrderTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<BooleanField source="active" />
				<TextField source="totalAmount" />
				<NumberField source="seqId" options={{ useGrouping: false }} />
				<NumberField source="fNo" options={{ useGrouping: false }} />
				<NumberField source="zNo" options={{ useGrouping: false }} />
				<TextField source="note" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
				<TextField source="createdBy" />
				<TextField source="updatedBy" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default OpenOrderShow;
