import ProductAddonSetIcon from '@material-ui/icons/LinearScale';
import ProductAddonSetList from './ProductAddonSetList';
import ProductAddonSetShow from './ProductAddonSetShow';
import ProductAddonSetEdit from './ProductAddonSetEdit';
import ProductAddonSetCreate from './ProductAddonSetCreate';


export default {
	list: ProductAddonSetList,
	show: ProductAddonSetShow,
	edit: ProductAddonSetEdit,
	create: ProductAddonSetCreate,
	icon: ProductAddonSetIcon,
	name:'productAddonSet'
};
