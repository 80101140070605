import React, { useEffect, useState } from 'react';
import {
	List,
	Filter,
	GET_LIST,
	AutocompleteInput
} from 'react-admin';

import { ReferenceAutocompleteInput, NameAutocompleteInput } from '../customInputs';
import RoleMappingDataGrid from './RoleMappingDataGrid';
import dataProvider from '../dataProvider';

const RoleMappingFilter = (props) => {

	const [companies, setCompanies] = useState([])
	const [allShops, setAllShops] = useState([])

	useEffect(() => {
		const fetchCompany = () => {
			dataProvider(GET_LIST, 'company', {
				filter: { active: true },
				sort: { field: 'name', order: 'DESC' },
				pagination: { page: null, perPage: null }
			})
				.then((res) => {
					const companies = res.data.map((x) => ({ id: x.id, name: x.name }));
					setCompanies(companies);
				})
				.catch((e) => {
					console.log('error', e)
				});
		};

		const fetchShop = () => {
			dataProvider(GET_LIST, 'shop', {
				filter: { active: true },
				sort: { field: 'name', order: 'DESC' },
				pagination: { page: null, perPage: null }
			})
				.then((res) => {
					const allShops = res.data.map((x) => ({ id: x.id, name: x.name, companyId: x.companyId }));
					setAllShops(allShops);
				})
				.catch((e) => {
					console.log('error', e)
				});
		};

		fetchCompany();
		fetchShop();

		return () => {
			setCompanies([])
			setAllShops([]) 
		}

	}, [])

 

	return (<Filter {...props}>
		<ReferenceAutocompleteInput source="principalId" reference="account" searchField="fullName" />
		<NameAutocompleteInput source="roleId" reference="roles" alwaysOn />
		<AutocompleteInput
			source="companyId"
			choices={companies}
			optionText="name"
			optionValue="id"
			alwaysOn/>
		<AutocompleteInput
			source="shopId"
			choices={allShops}
			optionText="name"
			optionValue="id"
			alwaysOn />
	</Filter>)
};

export const RoleMappingList = (props) => (
	<List filters={<RoleMappingFilter />} filterDefaultValues={{ active: true }} bulkActionButtons={false} {...props}>
		<RoleMappingDataGrid />
	</List>
);

export default RoleMappingList;
