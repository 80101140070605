import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import {
	fetchEnd,
	fetchStart,
	required,
	showNotification,
	Button,
	SaveButton,
	SimpleForm,
	TextInput
} from 'react-admin';
import IconLock from '@material-ui/icons/Lock';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { RESET_PASSWORD } from '../constants';
import dataProvider from '../dataProvider';

const formName = 'account-reset-password';

class AccountResetPasswordButton extends Component {
	state = {
		error: false,
		showDialog: false
	};

	handleClick = () => {
		this.setState({ showDialog: true });
	};

	handleCloseClick = () => {
		this.setState({ showDialog: false });
	};

	handleSaveClick = () => {
		const { submit } = this.props;

		// Trigger a submit of our custom quick create form
		// This is needed because our modal action buttons are oustide the form
		submit(formName);
	};

	handleSubmit = (values) => {
		const { fetchStart, fetchEnd, showNotification } = this.props;

		// Dispatch an action letting react-admin know a API call is ongoing
		fetchStart();

		// As we want to know when the new post has been created in order to close the modal, we use the
		// dataProvider directly
		dataProvider(RESET_PASSWORD, 'account/resetPass', { data: values })
			.then(({ data }) => {
				// Update the main react-admin form (in this case, the comments creation form)
				//change(REDUX_FORM_NAME, 'account_id', data.id);
				this.setState({ showDialog: false });
				showNotification('message.actionSuccess', 'success');
			})
			.catch((error) => {
				showNotification(error.message, 'error');
			})
			.finally(() => {
				// Dispatch an action letting react-admin know a API call has ended
				fetchEnd();
			});
	};

	render() {
		const { showDialog } = this.state;
		const { isSubmitting, record } = this.props;

		return (
			<Fragment>
				<Button variant="raised" onClick={this.handleClick} label="action.resetPassword">
					<IconLock />
				</Button>
				<Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Create post">
					<DialogTitle>Reset Password</DialogTitle>
					<DialogContent>
						<SimpleForm
							// We override the redux-form name to avoid collision with the react-admin main form
							form={formName}
							resource="account"
							// We override the redux-form onSubmit prop to handle the submission ourselves
							onSubmit={this.handleSubmit}
							// We want no toolbar at all as we have our modal actions
							toolbar={null}
						>
							<TextInput source="email" defaultValue={record.email} validate={required()} />
							<TextInput source="password" validate={required()} />
						</SimpleForm>
					</DialogContent>
					<DialogActions>
						<SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
						<Button label="ra.action.cancel" onClick={this.handleCloseClick}>
							<IconCancel />
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	isSubmitting: isSubmitting(formName)(state)
});

const mapDispatchToProps = {
	change,
	fetchEnd,
	fetchStart,
	showNotification,
	submit
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountResetPasswordButton);
