import React from 'react';
import { Edit, TextField, BooleanInput, NumberInput, DisabledInput, FormTab } from 'react-admin';

import { TabbedForm } from '../customViews';
import UnitTypeTitle from './UnitTypeTitle';

const UnitTypeEdit = (props) => (
	<Edit title={<UnitTypeTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<DisabledInput source="id" />
				<TextField source="name" />
				<NumberInput source="index" />
				{/* <ReferenceField source="quantityTypeId" reference="quantityType">
					<TextField source="name" />
				</ReferenceField> */}
				<NumberInput source="minQuantity" />
				<NumberInput source="maxQuantity" />
				<BooleanInput source="isQuantityRequired" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default UnitTypeEdit;
