import React from 'react';
import { List, TextInput, BooleanInput, Filter } from 'react-admin';

import CompanyAmountTypeDataGrid from './CompanyAmountTypeDataGrid';
import { NameAutocompleteInput } from '../customInputs';

const CompanyAmounTypeFilter = (props) => (
	<Filter {...props}>
		<TextInput source="name" alwaysOn />
		<NameAutocompleteInput source="companyId" reference="company" alwaysOn />
		<NameAutocompleteInput source="amountTypeId" reference="amountType" perPage={100} alwaysOn />
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const CompanyAmounTypeList = (props) => (
	<List
		filters={<CompanyAmounTypeFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<CompanyAmountTypeDataGrid />
	</List>
);
export default CompanyAmounTypeList;
