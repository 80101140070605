import React from 'react';
import { Show, TextField, BooleanField, ImageField, TabbedShowLayout, Tab, ReferenceManyField } from 'react-admin';

import { JsonField, DateField } from '../customFields';

import ShopTitle from './ShopTitle';

import EmployeeDataGrid from '../employees/EmployeeDataGrid';
import DeviceDataGrid from '../devices/DeviceDataGrid';
import ShiftDataGrid from '../shifts/ShiftDataGrid';

import OutputCenterDataGrid from '../outputCenters/OutputCenterDataGrid';
import { AddNewDeviceButton, AddNewEmployeeButton, AddNewOutputCenterButton } from './AddButtons';

export const ShopShow = (props) => (
	<Show title={<ShopTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<TextField source="logo" />
				<TextField source="city" />		
				<TextField source="postcode" />
				<TextField source="address" />
				<BooleanField source="active" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
			</Tab>
			<Tab label="Settings">
				<ImageField source="logo" />
				<JsonField source="settings" label="Settings" />
				<JsonField source="serverSettings" label="Server Settings" />
				<JsonField source="integrationSettings" label="Integrarion Settings" />
			</Tab>
			<Tab label="Shifts" path="shitfs">
				<ReferenceManyField
					reference="shift"
					target="shopId"
					sort={{ field: 'createdAt', order: 'DESC' }}
					addLabel={false}
					perPage={1000}
				>
					<ShiftDataGrid />
				</ReferenceManyField>
			</Tab>
			<Tab label="Employees" path="employees">
				<ReferenceManyField reference="employee" target="shopId" addLabel={false} perPage={1000}>
					<EmployeeDataGrid />
				</ReferenceManyField>
				<AddNewEmployeeButton />
			</Tab>
			<Tab label="Registers" path="registers">
				<ReferenceManyField reference="register" target="shopId" addLabel={false} perPage={1000}>
					<DeviceDataGrid />
				</ReferenceManyField>
				<AddNewDeviceButton />
			</Tab>
			<Tab label="Devices" path="devices">
				<ReferenceManyField reference="device" target="shopId" addLabel={false} perPage={1000}>
					<DeviceDataGrid />
				</ReferenceManyField>
				<AddNewDeviceButton />
			</Tab>
			<Tab label="OutputCenters" path="outputCenters">
				<ReferenceManyField reference="outputCenter" target="shopId" addLabel={false} perPage={1000}>
					<OutputCenterDataGrid />
				</ReferenceManyField>
				<AddNewOutputCenterButton />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ShopShow;
