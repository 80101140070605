import React from 'react';
import { Show, TextField, TabbedShowLayout, Tab } from 'react-admin';
import { withStyles } from '@material-ui/core';

import { JsonField, ReferenceField, DefaultFields, ShopReferenceField } from '../customFields';

import DeviceTitle from './DeviceTitle';

const styles = {
	inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
};

const DeviceShow = withStyles(styles)(({ classes, ...props }) => (
	<Show title={<DeviceTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				{ShopReferenceField(props)}
				<TextField source="name" />
				<TextField source="serialNumber" formClassName={classes.inlineBlock} />
				<TextField source="osName" formClassName={classes.inlineBlock} />
				<TextField source="osVersion" />
				<TextField source="appVersion" />
				<TextField source="ipAddress" />
				<TextField source="macAddress" />
				<ReferenceField source="deviceModelId" reference="deviceModel">
					<TextField source="name" />
				</ReferenceField>
				{DefaultFields(props)}
			</Tab>
			<Tab label="Settings" path="settings">
				<JsonField source="properties" />
			</Tab>
		</TabbedShowLayout>
	</Show>
));

export default DeviceShow;
