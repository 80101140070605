import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab } from 'react-admin';

import { DateField } from '../customFields';
import RoleMappingTitle from './RoleMappingTitle';

export const RoleMappingShow = (props) => {
	return (
		<Show title={<RoleMappingTitle />} {...props}>
			<TabbedShowLayout>
				<Tab label="Details">
					<TextField source="firstName" />
					<TextField source="lastName" />
					<TextField source="fullName" />
					<BooleanField source="checkedIn" />
					<BooleanField source="isDefault" />
					<BooleanField source="managesRoleMappings" />
					<BooleanField source="managesProducts" />
					<NumberField source="pinCode" />
					<NumberField source="type" />
					{/* <ReferenceField source="shopId" reference="shops">
					<TextField source="id" />
				</ReferenceField> */}

					<BooleanField source="active" />
					<DateField source="createdAt" />
					<DateField source="updatedAt" />
					<TextField source="createdBy" />
					<TextField source="updatedBy" />
				</Tab>
			</TabbedShowLayout>
		</Show>
	)
};

export default RoleMappingShow;
