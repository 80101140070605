import { DateField as ReactDateField } from 'react-admin';

const DateField = ReactDateField;

DateField.defaultProps = {
	showTime: true,
	locales: 'tr-TR',
	addLabel: true
};

export default DateField;
