import React from 'react';
import { Datagrid, NumberField, EditButton } from 'react-admin';

import { DefaultFields } from '../customFields';

const OpenOrderDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		<NumberField source="totalAmount" />
		<NumberField source="seqId" options={{ useGrouping: false }} />
		<NumberField source="fNo" options={{ useGrouping: false }} />
		<NumberField source="zNo" options={{ useGrouping: false }} />
		<NumberField source="note" />
		{DefaultFields(props)} <EditButton />
	</Datagrid>
);

export default OpenOrderDataGrid;
