import React from 'react';
import { Edit, TextInput, BooleanInput, DisabledInput, FormTab } from 'react-admin';

import { TabbedForm } from '../customViews';
import { JsonInput } from '../customInputs';
import RegisterTitle from './RegisterTitle';

const RegisterEdit = (props) => (
	<Edit title={<RegisterTitle />} {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="name" />
				<TextInput source="serialNumber" />
				<TextInput source="osName" />
				<TextInput source="osVersion" />
				<TextInput source="appVersion" />
				<TextInput source="ipAddress" />
				<TextInput source="macAddress" />
				<BooleanInput source="isOpen" />
				<BooleanInput source="isSimulator" />
				<BooleanInput source="active" />
				<DisabledInput source="createdAt" />
				<DisabledInput source="updatedAt" />
				<DisabledInput source="createdBy" />
				<DisabledInput source="updatedBy" />
			</FormTab>
			<FormTab label="Settings" path="settings">
				<JsonInput source="properties" />
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default RegisterEdit;
