import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import AmountTypeIcon from '@material-ui/icons/AttachMoney';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	button: {
		marginTop: '1em'
	}
};

export const AddNewAmountTypeButton = withStyles(styles)(({ classes, record }) => (
	<Button
		className={classes.button}
		variant="raised"
		component={Link}
		to={{
			pathname: '/companyAmountType/create',
			state: { record: { companyId: record.id, active: true } }
		}}
		label="Add Amount Type"
	>
		<AmountTypeIcon />
	</Button>
));
