import React from 'react';
import { Show, TextField, NumberField, TabbedShowLayout, Tab } from 'react-admin';
import { withStyles } from '@material-ui/core';

import { CompanyReferenceField, DefaultFields } from '../customFields';

import DiscountTitle from './DiscountTitle';

const styles = {
	inlineBlock: { display: 'inline-flex', marginRight: '1rem' }
};

const DiscountShow = withStyles(styles)(({ classes, ...props }) => (
	<Show title={<DiscountTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="code" />
				<TextField source="name" />
				<NumberField source="type" />
				<NumberField source="amount" />
				{CompanyReferenceField(props)}
				{DefaultFields(props)}
			</Tab>
		</TabbedShowLayout>
	</Show>
));

export default DiscountShow;
