import React from 'react';
import { Show, TextField, BooleanField, ImageField, TabbedShowLayout, Tab, ReferenceManyField } from 'react-admin';

import { JsonField, DateField } from '../customFields';

import ShopDataGrid from '../shops/ShopDataGrid';
import CompanyAmountTypeDataGrid from '../companyAmountTypes/CompanyAmountTypeDataGrid';
import { AddNewAmountTypeButton } from './AddButtons';

import CompanyTitle from './CompanyTitle';

const CompanyShow = (props) => (
	<Show title={<CompanyTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="name" />
				<TextField source="logo" />
				<ImageField source="logo" addLabel={false} />
				<BooleanField source="active" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
			</Tab>
			<Tab label="Settings" path="settings">
				<JsonField source="settings" label="Settings" />
				<JsonField source="integrationSettings" label="Integrarion Settings" />
			</Tab>
			<Tab label="Shops" path="shops">
				<ReferenceManyField reference="shop" target="companyId" addLabel={false} perPage={1000}>
					{/* <ShopList filter={{ companyId: props.id }}  {...props} /> */}
					<ShopDataGrid />
				</ReferenceManyField>
			</Tab>
			{/* <Tab label="Products" path="products">
				<ReferenceManyField reference="product" target="companyId" addLabel={false}>
					<ProductList />
				</ReferenceManyField>
			</Tab> */}p
			<Tab label="Company Amount Types" path="companyAmountTypes">
				<ReferenceManyField reference="companyAmountType" target="companyId" addLabel={false} perPage={1000}>
					<CompanyAmountTypeDataGrid />
				</ReferenceManyField>
				<AddNewAmountTypeButton />
			</Tab>
		</TabbedShowLayout>
	</Show>
);
export default CompanyShow;
