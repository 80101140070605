import React from 'react';
import { Show, TextField, BooleanField, NumberField, TabbedShowLayout, Tab, ReferenceField } from 'react-admin';

import { DateField } from '../customFields';
import ShopRevenueCenterTitle from './ShopRevenueCenterTitle';

const ShopRevenueCenterShow = (props) => (
    <Show title={<ShopRevenueCenterTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <NumberField source="index" />
                <TextField source="name" />
                <ReferenceField source="shopId" reference="shop" linkType="show">
					<TextField source="name" />
				</ReferenceField>
                <ReferenceField source="revenueCenterId" reference="revenueCenter" linkType="show">
					<TextField source="name" />
				</ReferenceField>
				<DateField source="createdAt"  />
				<DateField source="updatedAt"  /> 
                <BooleanField source="active" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ShopRevenueCenterShow;
