import React from 'react';
import { TabbedForm as ReactTabbedForm } from 'react-admin';

import { PayPadEditToolbar } from '../customToolbars';

const TabbedForm = ReactTabbedForm;

const toolbar = <PayPadEditToolbar />;

TabbedForm.defaultProps = {
	toolbar
};

export default TabbedForm;
