import React from 'react';
import { Show, TextField, BooleanField, TabbedShowLayout, Tab, ReferenceManyField } from 'react-admin';

import AccountTitle from './AccountTitle';
import CompanyDataGrid from '../companies/CompanyDataGrid';
import RoleMappingDataGrid from '../roleMappings/RoleMappingDataGrid.js';
import AccountResetPasswordButton from './AccountResetPasswordButton';
import { AddNewRoleMappingButton } from './AddButtons.js';
import { DateField } from '../customFields';

const AccountShow = (props) => (
	<Show title={<AccountTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="Details">
				<TextField source="username" />
				<TextField source="email" />
				<TextField source="firstName" />
				<TextField source="lastName" />
				<TextField source="fullName" />
				<TextField source="phoneNumber" />
				<BooleanField source="active" />
				<DateField source="createdAt" />
				<DateField source="updatedAt" />
				<AccountResetPasswordButton />
			</Tab>
			<Tab label="Roles" path="roles">
				<ReferenceManyField reference="roleMappings" target="principalId" addLabel={false} perPage={1000}>
					<RoleMappingDataGrid />
				</ReferenceManyField>
				<AddNewRoleMappingButton />
			</Tab>
			<Tab label="Companies" path="companies">
				<ReferenceManyField reference="company" target="userId" addLabel={false} perPage={1000}>
					<CompanyDataGrid />
				</ReferenceManyField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);
export default AccountShow;
