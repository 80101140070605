import CompanyIcon from '@material-ui/icons/AccountBalance';
import CompanyList from './CompanyList';
import CompanyShow from './CompanyShow';
import CompanyEdit from './CompanyEdit';

export default {
	list: CompanyList,
	show: CompanyShow,
	edit: CompanyEdit,
	icon: CompanyIcon,
	name: 'company'
};
