import React, { useEffect, useState } from 'react';
import {
	Create,
	SelectInput,
	TextInput,
	BooleanInput,
	FormTab,
	GET_LIST,
	number,
	minLength,
	maxLength
} from 'react-admin';
import { TabbedForm } from '../customViews';
import EmployeeTitle from './EmployeeTitle';
import { NameAutocompleteInput } from '../customInputs';
import dataProvider from '../dataProvider';


const EmployeeCreate = (props) => {
	const [employeeTypeList, setEmployeeTypeList] = useState([])

	useEffect(() => {
		const fetchData = () => {
			dataProvider(GET_LIST, 'employeeType', {
				filter: { active: true },
				sort: { field: 'name', order: 'DESC' },
				pagination: { page: 1, perPage: 50 }
			})
				.then((res) => {
					const employeeTypes = res.data.map((x) => ({ id: x.index, name: x.name }));
					setEmployeeTypeList(employeeTypes);
				})
				.catch((e) => {
					console.log('error', e)
				});
		};

		fetchData();
	}, []);

	return (
		<Create title={<EmployeeTitle />} {...props}>
			<TabbedForm>
				<FormTab label="Details">
					<NameAutocompleteInput source="shopId" reference="shop">
					</NameAutocompleteInput>
					<TextInput source="firstName" />
					<TextInput source="lastName" />
					<TextInput source="fullName" />
					<BooleanInput source="checkedIn" />
					<BooleanInput source="isDefault" />
					<BooleanInput source="managesEmployees" />
					<BooleanInput source="managesProducts" />
					<TextInput source="pinCode" validate={[number(), minLength(4), maxLength(8)]} />
					<SelectInput source="type" choices={employeeTypeList} />
					<BooleanInput source="active" />
				</FormTab>
			</TabbedForm>
		</Create>
	)
};

export default EmployeeCreate;
