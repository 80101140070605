import React from 'react';
import { List, Datagrid, BooleanField, NumberField, BooleanInput, Filter } from 'react-admin';

import { LookupFields } from '../customFields';
const UnitTypeFilter = (props) => (
	<Filter {...props}>
		<BooleanInput source="active" alwaysOn />
	</Filter>
);

const UnitTypeDataGrid = (props) => (
	<Datagrid rowClick="show" {...props}>
		{LookupFields(props)}
		{/* <ReferenceField source="quantityTypeId" reference="quantityType">
			<TextField source="name" />
		</ReferenceField> */}
		<NumberField source="minQuantity" />
		<NumberField source="maxQuantity" />
		<BooleanField source="isQuantityRequired" />
	</Datagrid>
);

const UnitTypeList = (props) => (
	<List
		filters={<UnitTypeFilter />}
		filterDefaultValues={{ active: true }}
		sort={{ field: 'createdAt', order: 'DESC' }}
		bulkActionButtons={false}
		{...props}
	>
		<UnitTypeDataGrid />
	</List>
);

export default UnitTypeList;
